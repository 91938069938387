import getClassName from "classnames";
import { filter, isEmpty, isEqual } from "lodash-es";
import memoizee from "memoizee";
import * as React from "react";
import { Prompt } from "react-router";
import * as ReduxForm from "redux-form";
import { validation } from "@core/utils";
import type { Prefixes } from "@triply/utils/Models";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import FontAwesomeButton from "#components/FontAwesomeButton/index.tsx";
import { Alert, FontAwesomeIcon, Label, PlainTextField } from "#components/index.ts";
import type { PrefixUpdate } from "#reducers/datasetManagement.ts";
import * as styles from "./style.scss";

const iriValidator = validation.toStringValidator(validation.iriValidations);
const prefixValidator = validation.toStringValidator(validation.prefixLabelValidations);
function uniqueValidator(prefixes: PrefixUpdate[], prefix: PrefixUpdate) {
  let pref: string | undefined = prefix.prefixLabel;
  if (isEqual(pref, "")) pref = undefined;
  const others = prefixes.find(function (prefix2) {
    let pref2: string | undefined = prefix2.prefixLabel;
    if (isEqual(pref2, "")) pref2 = undefined;
    return prefix !== prefix2 && pref === pref2;
  });
  if (others !== undefined && !isEmpty(others)) {
    return "Must be unique";
  }
}

function validateArray(prefixes: PrefixUpdate[]) {
  const prefFilter = prefixes.filter((item) => !isEmpty(item));
  return prefFilter.map((prefix) => {
    return {
      iri: iriValidator(prefix.iri),
      prefixLabel: prefixValidator(prefix.prefixLabel) || uniqueValidator(prefFilter, prefix),
    };
  });
}

namespace PrefixForm {
  export interface FormData {
    prefixes: PrefixUpdate[];
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    prefixes?: Prefixes;
    form: string;
  }

  export interface State {}
}
const PrefixForm = ReduxForm.reduxForm<PrefixForm.FormData, PrefixForm.Props>({
  validate: memoizee(
    (formData: PrefixForm.FormData) => {
      if (isEmpty(formData)) return {}; //Tmp fix, see https://github.com/erikras/redux-form/issues/4355
      return {
        prefixes: validateArray(formData.prefixes),
      } as any;
    },
    { max: 10 },
  ),
})(
  class PrefixForm extends React.PureComponent<
    PrefixForm.Props & Partial<ReduxForm.InjectedFormProps<FormData>>,
    PrefixForm.State
  > {
    renderPrefixes = (props: any) => {
      let newProps = {
        submitting: this.props.submitting,
        invalid: this.props.invalid,
      };
      return <GrowingFieldArray {...newProps} {...props} />;
    };
    render() {
      const { error, submitting, handleSubmit, submitSucceeded, submitFailed, invalid, pristine } = this.props;
      return (
        <div>
          <form method="POST" onSubmit={handleSubmit}>
            {/** rerendering on every change, because we want a new prefix line (the last one) to show up on-change of the last one**/}
            <ReduxForm.FieldArray name={"prefixes"} component={this.renderPrefixes} rerenderOnEveryChange />
            <Alert transparent message={error} />
            <div className="form-group">
              <LoadingButton
                type="submit"
                color="secondary"
                disabled={submitFailed || invalid || pristine}
                onClick={handleSubmit}
                loading={submitting}
              >
                Save
              </LoadingButton>
            </div>
          </form>
          {submitSucceeded && pristine && (
            <div className="mt-4">
              <Label success message="You have successfully updated the prefixes." />
            </div>
          )}
          <Prompt
            when={!pristine}
            message="The changes you made to the prefixes have not been saved yet. Are you sure you want to leave this page?"
          />
        </div>
      );
    }
  } as any,
);

export default PrefixForm;

namespace GrowingFieldArray {
  export interface Props {
    submitting: boolean;
    invalid: boolean;
  }
  export interface State {}
}

class GrowingFieldArray extends React.PureComponent<
  ReduxForm.WrappedFieldArrayProps<any> & GrowingFieldArray.Props,
  GrowingFieldArray.State
> {
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.fields.length === 0 || filter(nextProps.fields.getAll().slice(), isEmpty).length === 0) {
      this.props.fields.push({});
    }
  }

  render() {
    return (
      <div className={getClassName("py-3")}>
        <div className={styles.header}>
          <div className={styles.prefixLabel}>Prefix label</div>
          <div className={styles.prefixIri}>IRI</div>
        </div>
        <div>
          {this.props.fields.map((pref, index) => {
            return (
              <div key={index} className={getClassName(styles.prefixRow, "mb-3")}>
                <div className={styles.prefixLabel}>
                  <ReduxForm.Field name={`${pref}.prefixLabel`} immediateError component={PlainTextField} />{" "}
                </div>
                <div>:</div>
                <div className={styles.prefixIri}>
                  <ReduxForm.Field
                    className={styles.inputField}
                    name={`${pref}.iri`}
                    component={PlainTextField}
                    immediateError
                  />
                </div>
                {this.props.fields.length - 1 !== index ? ( // Create the delete button, and hide it for the empty field
                  <FontAwesomeButton
                    title="Delete prefix"
                    onClick={() => this.props.fields.remove(index)}
                    icon="times"
                  />
                ) : (
                  <div className={getClassName("clickableIcon")} style={{ visibility: "hidden" }}>
                    <FontAwesomeIcon icon="times" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
