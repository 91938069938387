import { InputAdornment, Typography } from "@mui/material";
import memoizee from "memoizee";
import * as React from "react";
import { connect } from "react-redux";
import * as ReduxForm from "redux-form";
import { validation } from "@core/utils";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import {
  Alert,
  Button,
  FontAwesomeIcon,
  FormField,
  MuiPasswordFieldRedux,
  MuiTextFieldRedux,
  SimpleDateField,
} from "#components/index.ts";
import type MuiCheckbox from "#components/MuiCheckbox/index.tsx";
import { MuiCheckboxRedux } from "#components/MuiCheckbox/index.tsx";
import type { NewPasswordProps } from "#components/MuiPasswordField/index.tsx";
import { checkAccountName } from "#reducers/accounts.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";

const emailValidator = validation.toStringValidator(validation.emailValidations);
const userNameValidator = validation.toStringValidator(
  validation.getAccountNameValidations({ messageSubject: "A user name" }),
);
const passwordValidator = validation.toStringValidator(validation.passwordValidations);

namespace Register {
  export interface FormData {
    accountName: string;
    email: string;
    password?: string;
    expiresAt?: string;
    consent?: boolean;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState & Partial<ReduxForm.InjectedFormProps<FormData>>;
  export interface OwnProps extends Partial<ReduxForm.ConfigProps<FormData>> {
    registeredByAdmin?: boolean;
    cancel?: React.EventHandler<React.MouseEvent<any>>;
    className?: string;
    submitButtonLabel?: string;
  }
  export interface DispatchProps {
    checkAccountName?: DispatchedFn<typeof checkAccountName>;
  }
  export interface PropsFromState {
    privacyPolicyUrl?: string;
    generalTermsUrl?: string;
  }
}

const RegisterForm: React.FC<Register.Props> = ({
  asyncValidating,
  handleSubmit,
  error,
  submitting,
  pristine,
  invalid,
  cancel,
  registeredByAdmin,
  className,
  generalTermsUrl,
  privacyPolicyUrl,
  submitButtonLabel,
}) => {
  const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(false);
  return (
    <form className={className} onSubmit={handleSubmit}>
      <FormField label="User name" className="mb-5" inputId="accountName">
        <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
          name="accountName"
          props={{
            type: "text",
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon style={{ cursor: "default" }} icon="user" />
                </InputAdornment>
              ),
              endAdornment: !!asyncValidating && (
                <InputAdornment position="end">
                  <FontAwesomeIcon style={{ cursor: "default" }} icon="cog" spin />
                </InputAdornment>
              ),
            },
            inputProps: {
              id: "accountName",
            },
            autoComplete: "username",
            autoFocus: true,
            formIsPristine: pristine,
            fullWidth: true,
          }}
          component={MuiTextFieldRedux}
        />
      </FormField>
      <FormField label="Email address" className="mb-5" inputId="email">
        <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
          name="email"
          props={{
            type: "email",
            inputProps: {
              id: "email",
            },
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon style={{ cursor: "default" }} icon="envelope" />
                </InputAdornment>
              ),
            },
            autoComplete: "email",
            autoFocus: false,
            formIsPristine: false,
            fullWidth: true,
          }}
          component={MuiTextFieldRedux}
        />
      </FormField>
      {!registeredByAdmin && (
        <FormField label="Password" className="mb-5" inputId="password">
          <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props & NewPasswordProps>>
            name="password"
            props={{
              inputProps: {
                id: "password",
              },
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon style={{ cursor: "default" }} icon="lock" />
                  </InputAdornment>
                ),
              },
              autoComplete: "new-password",
              fullWidth: true,
              isNewPasswordField: true,
              setIsPasswordValid: (boolean) => setIsPasswordValid(boolean),
            }}
            component={MuiPasswordFieldRedux}
          />
        </FormField>
      )}
      {registeredByAdmin && (
        <div>
          <FormField label="Account expiration date" className="mb-6">
            <SimpleDateField.Field
              name="expiresAt"
              startDate={new Date().toISOString().slice(0, 10)}
              component={SimpleDateField}
            />
          </FormField>
        </div>
      )}
      {!registeredByAdmin && (generalTermsUrl || privacyPolicyUrl) && (
        <FormField label="" className="mb-5">
          <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiCheckbox.Props>>
            name="consent"
            props={{
              label: (
                <Typography variant="body2">
                  I agree with the{" "}
                  <a href={generalTermsUrl} target="_blank">
                    General terms
                  </a>{" "}
                  and{" "}
                  <a href={privacyPolicyUrl} target="_blank">
                    Privacy policy
                  </a>
                </Typography>
              ),
              color: "primary",
              helperText: "To create an account, you need to agree with our general terms and privacy policy.",
              size: "small",
            }}
            component={MuiCheckboxRedux}
          />
        </FormField>
      )}
      <Alert transparent message={error} />
      <div style={{ marginTop: 20, display: "flex", alignItems: "baseline" }}>
        <LoadingButton
          disabled={!registeredByAdmin ? pristine || invalid || !isPasswordValid : pristine || invalid}
          type="submit"
          color="secondary"
          onClick={handleSubmit}
          loading={submitting}
        >
          {submitButtonLabel || "Register"}
        </LoadingButton>
        {!!cancel && (
          <Button onClick={cancel} className="ml-2" variant="text">
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
};

const Register = connect<{}, { [K in keyof Register.DispatchProps]: any }, Register.OwnProps, GlobalState>(
  (state: GlobalState) => {
    return {
      privacyPolicyUrl: state.config.clientConfig?.legal?.privacyPolicyUrl,
      generalTermsUrl: state.config.clientConfig?.legal?.generalTermsUrl,
    };
  },
  {
    checkAccountName: checkAccountName,
  },
)(
  ReduxForm.reduxForm<Register.FormData, Register.Props>({
    form: "register",
    validate: memoizee(
      (formData: Register.FormData, props: Register.Props) => {
        return {
          email: emailValidator(formData.email),
          accountName: userNameValidator(formData.accountName),
          password: passwordValidator(formData.password),
          consent:
            (props.privacyPolicyUrl || props.generalTermsUrl) && !formData.consent && !props.registeredByAdmin
              ? "To create an account, you need to agree with our general terms and privacy policy."
              : undefined,
        };
      },
      { max: 10 },
    ),
    asyncBlurFields: ["accountName"],
    asyncValidate: function asyncValidate(values: Register.FormData, _dispatch: any, props: Register.Props) {
      if (!values.accountName || !props.checkAccountName) {
        return Promise.resolve({});
      }
      //promise should return object with key as form key, and value as error message
      return props.checkAccountName("accountName", values.accountName);
    },
  })(RegisterForm as any),
);

export default Register;
