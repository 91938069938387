import { Collapse, Fade, ListItemAvatar, ListItemButton, ListItemText, Tooltip } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "#components/index.ts";
import { toggleSidePanelCollapsed } from "#reducers/app.ts";
import * as styles from "../PanelItem/style.scss";

interface Props {
  children: React.ReactNode;
  icon: FontAwesomeIcon.Props["icon"];
  name: string;
  active: boolean;
  panelCollapsed: boolean;
}

const PanelGroup: React.FC<Props> = ({ children, icon, name, active, panelCollapsed }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(active);

  const clickHandler = () => {
    if (panelCollapsed) {
      dispatch(toggleSidePanelCollapsed(false));
      setExpanded(true);
    } else {
      setExpanded((expanded) => !expanded);
    }
  };

  return (
    <>
      <Tooltip
        title={panelCollapsed ? name : ""}
        placement="right"
        enterDelay={0}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
      >
        <ListItemButton
          className={getClassName(styles.item, { [styles.active]: active })}
          onClick={clickHandler}
          aria-expanded={expanded && !panelCollapsed}
          aria-controls={`${name}-collapsible`}
        >
          <ListItemAvatar className={styles.collapsedAvatar}>
            <FontAwesomeIcon icon={icon} size="lg" fixedWidth />
          </ListItemAvatar>
          {!panelCollapsed && <ListItemText className={styles.text}>{name}</ListItemText>}
          {!panelCollapsed && <FontAwesomeIcon icon={expanded ? "angle-down" : "angle-right"} />}
        </ListItemButton>
      </Tooltip>

      <Collapse id={`${name}-collapsible`} in={expanded && !panelCollapsed} timeout={200}>
        {children}
      </Collapse>
    </>
  );
};

export default PanelGroup;
