import { Autocomplete, TextField } from "@mui/material";
import * as React from "react";
import type { ControllerProps } from "react-hook-form";
import { Controller } from "react-hook-form";
import { factories } from "@triplydb/data-factory";
import { getPrefixAndLabel } from "../../../components/Prefixed";
import { useDatasetPrefixes } from "../../../helpers/hooks/useDatasetPrefixes";
import type { ResourceData, ValueInfo } from "./Types";

const factory = factories.compliant;
const PropertyValueField: React.FC<
  Pick<ControllerProps<ResourceData, `properties.${number}.value`>, "name" | "control" | "rules"> & {
    datatype: string | undefined;
    values: ValueInfo[];
  }
> = ({ name, control, rules, datatype, values }) => {
  const prefixes = useDatasetPrefixes();
  if (datatype) {
    return (
      <Controller
        name={name}
        control={control}
        rules={{
          ...rules,
          validate: (value) => {
            try {
              if (value?.datatype) factory.literal(value.id, factory.namedNode(value.datatype!));
            } catch (e: any) {
              const { prefix, label } = getPrefixAndLabel(prefixes, value!.datatype!);
              console.error(e);
              return `Not a valid ${prefix ? `${prefix}:${label}` : label}.`;
            }
          },
        }}
        defaultValue={null}
        render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
          <TextField
            {...rest}
            onChange={(e) => {
              onChange({ id: e.target.value, datatype: datatype });
            }}
            value={value?.id || ""}
            error={!!error}
            helperText={error?.message}
          />
        )}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={null}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          options={values}
          onChange={(_e, data) => onChange(data)}
          renderInput={(params) => (
            <TextField
              {...(params as any)}
              error={!!error}
              helperText={error?.message || (typeof rest.value !== "string" && rest.value?.description)}
            />
          )}
          isOptionEqualToValue={(option: any, value: any) => {
            return option.id === value.id;
          }}
          {...(rest as any)}
        />
      )}
    />
  );
};

export default PropertyValueField;
