import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { validation } from "@core/utils";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import type { MarkdownEditField } from "#components/index.ts";
import { Alert, FormField, Label, MarkdownEditFieldRedux, MuiTextFieldRedux } from "#components/index.ts";

const nameValidator = validation.toStringValidator(validation.siteNameValidations);
const taglineValidator = validation.toStringValidator(validation.siteTaglineValidations);
const descriptionValidator = validation.toStringValidator(validation.siteDescriptionValidations);
const welcomeTextValidator = validation.toStringValidator(validation.siteWelcomeTextValidations);

namespace ApplicationMetadata {
  export interface FormData {
    description: string;
    name: string;
    tagline: string;
    welcomeText: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {}
}

const ApplicationMetadata = ReduxForm.reduxForm<ApplicationMetadata.FormData, ApplicationMetadata.Props>({
  form: "appMetadata",
  validate: memoizee(
    (formData: ApplicationMetadata.FormData) => {
      return {
        name: nameValidator(formData.name),
        description: descriptionValidator(formData.description),
        tagline: taglineValidator(formData.tagline),
        welcomeText: welcomeTextValidator(formData.welcomeText),
      };
    },
    { max: 10 },
  ),
})(
  class ApplicationMetadataForm extends React.PureComponent<
    ApplicationMetadata.Props & Partial<ReduxForm.InjectedFormProps<ApplicationMetadata.FormData>>,
    any
  > {
    render() {
      const { handleSubmit, submitting, submitSucceeded, error, pristine } = this.props;

      return (
        <div>
          <form method="POST" onSubmit={handleSubmit}>
            <div>
              <FormField
                label="Name"
                helperText="The name of the site. Typically one or two words."
                className="mt-6 mb-6"
              >
                <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                  name="name"
                  props={{
                    fullWidth: true,
                  }}
                  component={MuiTextFieldRedux}
                />
              </FormField>
              <FormField
                label="Tag line"
                helperText="A short description of the site in one sentence."
                className="mb-6"
              >
                <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                  name="tagline"
                  props={{
                    fullWidth: true,
                  }}
                  component={MuiTextFieldRedux}
                />
              </FormField>
              <FormField
                label="Description"
                helperText="A text-only description of the site that is used for metadata."
                className="mb-6"
              >
                <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                  name="description"
                  props={{
                    fullWidth: true,
                    multiline: true,
                    rows: 3,
                  }}
                  component={MuiTextFieldRedux}
                />
              </FormField>
              <FormField
                label="Welcome text"
                helperText="A description of the site that appears on the homepage (supports Markdown)."
                className="mb-6"
              >
                <ReduxForm.Field<ReduxForm.BaseFieldProps<MarkdownEditField.Props>>
                  name="welcomeText"
                  props={{
                    fullWidth: true,
                    multiline: true,
                    rows: 8,
                  }}
                  component={MarkdownEditFieldRedux}
                />
              </FormField>
            </div>
            <Alert transparent message={error} />
            <div className="form-group mt-5">
              <LoadingButton
                type="submit"
                color="secondary"
                disabled={submitting || pristine}
                onClick={handleSubmit}
                loading={submitting}
              >
                Save
              </LoadingButton>
            </div>
          </form>
          {submitSucceeded && pristine && (
            <div className="mt-4">
              <Label success message="You have successfully updated the metadata." />
            </div>
          )}
        </div>
      );
    }
  } as any,
);

export default ApplicationMetadata;
