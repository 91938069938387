import { MenuItem } from "@mui/material";
import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import { connect } from "react-redux";
import * as ReduxForm from "redux-form";
import type { MarkRequired } from "ts-essentials";
import { validation } from "@core/utils";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import type { MuiAutosuggest } from "#components/index.ts";
import { Alert, Avatar, FormField, Highlight, MuiAutosuggestRedux } from "#components/index.ts";
import { AclContext } from "#context.ts";
import type { Acl } from "#helpers/Acl.ts";
import fetch from "#helpers/fetch.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { checkAccountName } from "#reducers/accounts.ts";
import type { DispatchedFn, GlobalState } from "#reducers/index.ts";

type AccountWithName = MarkRequired<Account, "accountName">;

const nameValidator = validation.toStringValidator([validation.required], {
  formatMessage: () => `An account name is required`,
});

namespace TransferDataset {
  export interface FormData {
    name: string;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState & Partial<ReduxForm.InjectedFormProps<FormData>>;
  export interface OwnProps extends Partial<ReduxForm.ConfigProps<FormData>> {
    className?: string;
    accountsUrl: string;
    currentAccount: Account;
  }
  export interface DispatchProps {
    checkAccountName: DispatchedFn<typeof checkAccountName>;
  }
  export interface PropsFromState {}
  export interface State {}
  export type searchAccounts = (searchString: string) => Promise<Account[]>;
}

// //use withRef: we need access to the form from a modal parent, to start submitting it
const TransferDataset = connect<
  TransferDataset.State,
  { [K in keyof TransferDataset.DispatchProps]: any },
  TransferDataset.OwnProps,
  GlobalState
>(
  () => ({}),
  {
    checkAccountName: checkAccountName,
  },
  undefined,
  { forwardRef: true },
)(
  ReduxForm.reduxForm<TransferDataset.FormData, TransferDataset.Props>({
    form: "transferDataset",
    validate: memoizee(
      (formData: TransferDataset.FormData) => {
        return {
          name: nameValidator(formData.name),
        };
      },
      { max: 10 },
    ),
  })(
    class TransferDataset extends React.PureComponent<TransferDataset.Props, TransferDataset.State> {
      static contextType = AclContext;
      context!: Acl;
      getAccounts() {
        //If we have permission to manage users of this instance, support a full user-search
        if (this.context.check({ action: "manageUsers" }).granted)
          return (queryString: string) => this.search(this.props.accountsUrl + "?substring=" + queryString);
        return this.getFilteredAccounts();
      }

      getFilteredAccounts() {
        const { currentAccount } = this.props;
        const accounts: AccountWithName[] =
          currentAccount.type === "user"
            ? (currentAccount.orgs?.filter((a) => !!a.accountName) as AccountWithName[]) || []
            : ((currentAccount.members || [])
                .filter((m) => m.role === "owner" && m.user.accountName)
                .map((m) => m.user) as AccountWithName[]);
        return () => Promise.resolve(accounts.filter((a) => !!a.accountName));
      }

      search(url: string): Promise<AccountWithName[]> {
        return this.fetch(url).then((results) => {
          if (results && Array.isArray(results)) {
            return results.filter((a) => !!a.accountName) as AccountWithName[];
          }
          return [];
        });
      }

      fetch = memoizee(
        async (url: string) => {
          return fetch(url, { credentials: "same-origin" })
            .then((response) => {
              if (response.status === 200) return response.json() as Promise<Account[]>;
            })
            .catch((error) => {
              console.error(error);
            });
        },
        { primitive: true, async: true },
      );

      getAccountName = (account: Account) => account.accountName;

      render() {
        const { handleSubmit, error, submitting, className, valid, pristine } = this.props;

        return (
          <form onSubmit={handleSubmit} className={getClassName(className)}>
            <FormField label="User or organization" className="mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiAutosuggest.Props<MarkRequired<Account, "accountName">>>>
                name="name"
                props={{
                  loadSuggestions: this.getAccounts(),
                  transformSuggestionToReduxValue: (account) => account.accountName,
                  getSuggestionSearchText: (account) => account.accountName,
                  renderSuggestion: (account, { query, isHighlighted }) => {
                    return (
                      <MenuItem selected={isHighlighted} component="div">
                        {account.accountName && (
                          <Avatar
                            size="sm"
                            className="mr-2"
                            avatarName={account.accountName}
                            avatarUrl={account.avatarUrl}
                            alt=""
                          />
                        )}
                        <Highlight fullText={account.accountName} highlightedText={query} />
                      </MenuItem>
                    );
                  },
                  TextFieldProps: {
                    fullWidth: true,
                  },
                }}
                component={MuiAutosuggestRedux}
              />
            </FormField>

            <Alert transparent message={error} className="mt-5" />

            <LoadingButton
              type="submit"
              color="secondary"
              disabled={pristine || !valid}
              onClick={handleSubmit}
              loading={submitting}
            >
              Transfer ownership
            </LoadingButton>
          </form>
        );
      }
    } as any,
  ) as any,
);
export default TransferDataset;
