import type { DeepOmit } from "ts-essentials";
import type { TriplyDBChartSchema } from "@core/utils/values";
import { readValuesFromFile, valuesWatcher } from "@core/utils/values-utils";
/**
 * Exclude props that we don't need or use
 */
export type Values = DeepOmit<
  TriplyDBChartSchema,
  {
    // May also be passed via a secret
    license: never;
    redis: never;
    mongodb: never;
    "mongodb-operator": never;
    mongodbSubChart: never;
    extraDeploy: never;
    cronjobs: never;
  }
>;

let values: Values | undefined;
export function getValues() {
  if (!values) {
    values = readValuesFromFile();
    // only watch in production. Easy way to get this to work nicefully with unit tests,
    // that otherwise hang because of the open watch
    if (process.env.NODE_ENV === "production") {
      valuesWatcher({
        onReload: (newValues) => {
          console.info("Reloading values file");
          values = newValues;
        },
      });
    }
  }

  return values as Values;
}
