import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import type { ColumnDef, ColumnFiltersState, FilterFn, SortingFn } from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import getClassName from "classnames";
import * as React from "react";
import { formatNumber } from "@core/utils/formatting";
import { getPrefixed } from "@triply/utils/prefixUtils";
import { TablePaginationActions } from "#components/ReactTableUtils/TableFooter.tsx";
import TextFilter from "#components/ReactTableUtils/TextFilter.tsx";
import type { Binding, ConstructResult, Term } from "#components/Sparql/SparqlUtils.ts";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext.tsx";
import { EmptyResults } from "../displayUtils.tsx";
import LargeResultWidget from "../LargeResultWidget.tsx";
import TermCell from "./TermCell.tsx";
import * as styles from "./styles.scss";

const DEFAULT_PAGESIZE = 20;

export type CellContent = Term | undefined;

const termSort: SortingFn<CellContent> = (rowA, rowB, columnId) => {
  return (
    rowA.getValue<CellContent>(columnId)?.value.localeCompare(rowB.getValue<CellContent>(columnId)?.value || "") || -1
  );
};

export interface PluginConfig {
  pageSize?: number;
}

export interface Props {
  hideFilters?: boolean;
  hidePagination?: boolean;
}

const TableDecider: React.FC<Props> = ({ hideFilters = false, hidePagination = false }) => {
  const { checkLimits } = React.useContext(SparqlVisualizationContext);
  const { results, isAsk, noResults } = useSparqlResults();
  const [showLargeResult, setShowLargeResult] = React.useState(false);
  if (noResults) return null;
  // Ask query table

  if (isAsk) {
    return <AskTable value={results} />;
  } else {
    if (checkLimits && results.length > 10_000 && !showLargeResult) {
      return <LargeResultWidget exceededSize="amountOfResults" onContinue={() => setShowLargeResult(true)} />;
    }
    // Others
    return <RawTable hideFilters={hideFilters} hidePagination={hidePagination} />;
  }
};

const AskTable: React.FC<{ value: boolean }> = ({ value }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Boolean</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{value ? "true" : "false"}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
const RawTable: React.FC<Props> = ({ hideFilters = false, hidePagination = false }) => {
  const { setVisualizationConfig, getVisualizationConfig, prefixes, checkLimits } =
    React.useContext(SparqlVisualizationContext);
  const { results, variables, emptyResults, isTabular } = useSparqlResults();
  const config = getVisualizationConfig("Table");
  const [pageSize, setPageSize] = React.useState(config?.pageSize || DEFAULT_PAGESIZE);
  const columns: ColumnDef<ConstructResult | Binding, CellContent>[] = React.useMemo<
    ColumnDef<ConstructResult | Binding, CellContent>[]
  >(() => {
    return (variables || []).map((key) => ({
      header: key,
      accessorKey: key,
      filterFn: "termFilter" as any, // Should be the same as the one we assign in the useReactTable hook.
      sortingFn: "termSort" as any, // Should be the same as the one we assign in the useReactTable hook.
      cell: TermCell,
    }));
  }, [variables]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const termFilter: FilterFn<CellContent> = React.useCallback(
    (row, columnId, filterValue) => {
      // First check the full IRI
      const cellValue = row.getValue<CellContent>(columnId)?.value.toLowerCase();
      if (cellValue?.includes(filterValue.toLowerCase())) return true;
      // Check prefixed version of IRI
      const prefixedCellValue = getPrefixed(cellValue || "", prefixes)?.toLowerCase();
      return prefixedCellValue?.includes(filterValue.toLowerCase()) || false;
    },
    [prefixes],
  );

  React.useEffect(() => {
    if (config?.pageSize) {
      setPageSize(config?.pageSize);
    }
  }, [config?.pageSize]);
  // Ask table should be drawn before the other tables
  if (!isTabular) throw new Error("Tabular table expectes tabular Data");
  const table = useReactTable<ConstructResult | Binding>({
    data: results,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: "onChange",
    sortingFns: {
      termSort: termSort,
    },
    filterFns: {
      termFilter: termFilter,
    },
    state: {
      columnFilters,
      pagination: {
        pageIndex: pageIndex,
        pageSize: pageSize || DEFAULT_PAGESIZE,
      },
    },
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: (newState) => {
      const paginationState =
        typeof newState === "function"
          ? newState({
              pageIndex: pageIndex,
              pageSize: pageSize || DEFAULT_PAGESIZE,
            })
          : newState;
      setPageIndex(paginationState.pageIndex);
      // When we have a setVsetVisualizationConfig, updating pageSize should be handled by the useEffect
      if (setVisualizationConfig) {
        setVisualizationConfig("Table", { pageSize: paginationState.pageSize });
      } else {
        setPageSize(paginationState.pageSize);
      }
    },
  });

  if (emptyResults) {
    return <EmptyResults />;
  }
  return (
    <TableContainer className={getClassName(styles.tableContainer)}>
      <Table size="small" stickyHeader className={styles.table} style={{ width: table.getCenterTotalSize() }}>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell key={header.id} style={{ width: header.getSize() }} className={styles.headerCell}>
                  <div className={getClassName(styles.header)}>
                    <TableSortLabel
                      active={!!header.column.getIsSorted()}
                      direction={header.column.getIsSorted() || undefined}
                      onClick={() => header.column.toggleSorting()}
                      className={styles.headerButton}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableSortLabel>
                    {!hideFilters && <TextFilter column={header.column as any} />}
                    <Divider
                      className={getClassName(styles.dragHandle, {
                        [styles.isDragging]: header.column.getIsResizing(),
                      })}
                      variant="middle"
                      orientation="vertical"
                      flexItem
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                    />
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow hover key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell key={cell.id} className={styles.cell}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        className={getClassName(styles.footer, {
          [styles.hidePaginationPerRows]: hidePagination,
          [styles.hidePagination]:
            hidePagination && table.getPrePaginationRowModel().rows.length <= (pageSize || DEFAULT_PAGESIZE),
        })}
        count={table.getPrePaginationRowModel().rows.length}
        rowsPerPageOptions={[10, 20, 50]}
        rowsPerPage={pageSize || DEFAULT_PAGESIZE}
        page={pageIndex}
        onPageChange={(_event, newPage) => table.setPageIndex(newPage)}
        onRowsPerPageChange={(event) => table.setPageSize(+event.target.value)}
        component="div"
        ActionsComponent={TablePaginationActions}
        labelDisplayedRows={(paginationInfo) => {
          const { from, to, count } = paginationInfo;
          return <span>{`${formatNumber(from)} - ${formatNumber(to)} of ${formatNumber(count)}`}</span>;
        }}
      />
    </TableContainer>
  );
};

export default React.memo(TableDecider);
