import memoizee from "memoizee";
import type { Role as AclRole } from "@core/utils/acl/index";
import { default as BaseAcl } from "@core/utils/acl/index";
import type { Account, State as AccountCollectionState } from "#reducers/accountCollection.ts";
import { getRoleInAccount, getRoleInAccountFromState } from "#reducers/accounts.ts";

export type Role = AclRole;
interface AuthenticatedUser {
  role: Role;
  accountName?: string;
  accountId?: string;
}

export class Acl extends BaseAcl<Role, PredefinedContext> {
  public ctx: AuthenticatedUser;

  constructor(opts: {
    authenticatedUser: AuthenticatedUser;
    predefinedContext: (() => PredefinedContext) | PredefinedContext;
    predefinedRole: (() => Role) | Role;
  }) {
    super({
      ...opts,
    });
    this.ctx = opts.authenticatedUser;
  }

  /**
   * This function is here for backwards compatability. We'd prefer using the
   * `getRoleInAccountFromState` instead, as this wont require us to create the
   * `Account` object first (as that causes unnecessary rerenders)
   */
  public getRoleInAccount(account?: Account) {
    return getRoleInAccount(this.ctx?.accountName, account);
  }
  public getRoleInAccountFromState(state: AccountCollectionState, accountId?: string) {
    return getRoleInAccountFromState(state, this.ctx?.accountId, accountId);
  }

  public isAuthenticatedUser(account?: Account) {
    return !!account && account.accountName === this.ctx?.accountName;
  }
}

export interface PredefinedContext {
  tokenType: "web";
  adminTokensAllowed: boolean;
}

function _getPredefinedAcl(accountName: string | undefined, accountUid: string | undefined, _role: Role | undefined) {
  const role = _role || "none";
  return new Acl({
    authenticatedUser: {
      accountName: accountName,
      accountId: accountUid,
      role: role,
    },
    predefinedRole: role,
    predefinedContext: {
      tokenType: role !== "none" ? "web" : undefined,
      adminTokensAllowed: false,
    } as PredefinedContext,
  });
}

export const getPredefinedAcl = memoizee(_getPredefinedAcl);
