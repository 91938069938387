import { Alert } from "@mui/material";
import { toPairs } from "lodash-es";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import { FormFieldSkeleton } from "#components/FormField/index.tsx";
import useSparql from "#helpers/hooks/useSparql.ts";
import PropertyGroup from "../PropertyGroup";
import PropertyShape from "./PropertyShape";
import type { FormValues } from "./Types";

const factory = factories.compliant;
const NO_GROUP = "none";

const NodeShape: React.FC<{ classIri: string; namePrefix: "properties" }> = ({ classIri, namePrefix }) => {
  const {
    data: groups,
    error,
    loading,
  } = useSparql<
    {
      groupIri: string;
      groupName: string;
      properties: Array<{
        path: string;
        name?: string;
        readOnly?: boolean;
        description?: string;
        datatype?: string;
        type?: string;
        nodeKind: "Literal" | "IRI" | "NestedNode";
        defaultValue?: string;
        defaultValueLabel?: string;
        minCount?: string;
        maxCount?: string;
        editor?: string;
        nestedNodeStem?: string;
      }>;
    }[]
  >(
    classIri &&
      `
# NodeShape
prefix sh: <http://www.w3.org/ns/shacl#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix editor: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>
prefix dash: <http://datashapes.org/dash#>
prefix triply: <https://triplydb.com/Triply/function/>

select
  ?groupIri
  (triply:firstLabel(?groupIri) as ?groupName)
  ?properties_path
  (coalesce(sample(?properties_shName), triply:firstLabel(?properties_path)) as ?properties_name)
  ?properties_readOnly
  (sample(?properties_description_t) as ?properties_description)
  ?properties_datatype
  ?properties_type
  ?properties_nodeKind
  ?properties_nestedNodeStem
  ?properties_defaultValue
  (triply:firstLabel(?properties_defaultValue) as ?properties_defaultValueLabel)
  ?properties_minCount
  ?properties_maxCount
  ?properties_editor
where {
  ${termToString(factory.namedNode(classIri))} rdfs:subClassOf*/^sh:targetClass/sh:property ?properties_propertyShape .
  ?properties_propertyShape sh:path ?properties_path .
  optional {
    ?properties_propertyShape sh:order ?properties_order .
  }

  optional {
    ?properties_propertyShape sh:group ?_groupIri .
    optional {
      ?_groupIri sh:order ?groupOrder .
    }
  }
  bind(coalesce(?_groupIri, "${NO_GROUP}") as ?groupIri)

  optional {
    ?properties_propertyShape sh:name ?properties_shName .
    filter(langMatches(lang(?properties_shName), "nl"))
  }
  optional {
    ?properties_propertyShape sh:name ?properties_shName .
    filter(langMatches(lang(?properties_shName), "en"))
  }
  optional {
    ?properties_propertyShape sh:name ?properties_shName .
  }
  optional {
    ?properties_propertyShape editor:readOnly ?properties_readOnly .
  }
  optional {
    ?properties_propertyShape sh:description ?properties_description_t
    filter(langMatches(lang(?properties_description_t), "nl"))
  }
  optional {
    ?properties_propertyShape sh:description ?properties_description_t
    filter(langMatches(lang(?properties_description_t), "en"))
  }
  optional {
    ?properties_propertyShape sh:description ?properties_description_t
  }
  optional {
    ?properties_path rdfs:comment ?properties_description_t
    filter(langMatches(lang(?properties_description_t), "nl"))
  }
  optional {
    ?properties_path rdfs:comment ?properties_description_t
    filter(langMatches(lang(?properties_description_t), "en"))
  }
  optional {
    ?properties_path rdfs:comment ?properties_description_t
  }
  optional {
    ?properties_propertyShape sh:datatype ?properties_datatype .
  }
  optional {
    ?properties_propertyShape sh:class ?properties_type .
    optional {
      ?properties_propertyShape dash:editor dash:DetailsEditor .
      bind(true as ?nestedNode)

      optional {
        ?properties_type rdfs:subClassOf*/^sh:targetClass/dash:stem ?properties_nestedNodeStem
      }
    }
  }
  bind(if(bound(?properties_type), if(bound(?nestedNode), "NestedNode", "IRI"), "Literal") as ?properties_nodeKind)

  optional {
    ?properties_propertyShape sh:minCount ?properties_minCount .
  }
  optional {
    ?properties_propertyShape sh:maxCount ?properties_maxCount .
  }
  optional {
    ?properties_propertyShape sh:defaultValue ?properties_defaultValue .
  }
  optional {
    ?properties_propertyShape dash:editor ?properties_editor .
  }
}
group by
  ?groupIri
  ?groupOrder
  ?properties_path
  ?properties_readOnly
  ?properties_datatype
  ?properties_type
  ?properties_nodeKind
  ?properties_nestedNodeStem
  ?properties_defaultValue
  ?properties_minCount
  ?properties_maxCount
  ?properties_editor
  ?properties_order
order by (!bound(?groupOrder)) coalesce(?groupOrder, -100000) (!bound(?properties_order)) ?properties_order

    `,
    {
      singularizeVariables: {},
    },
  );

  const { getValues } = useFormContext<FormValues>();

  if (loading)
    return (
      <>
        <FormFieldSkeleton />
        <FormFieldSkeleton />
      </>
    );

  if (error) return <Alert severity="error">Failed to load.</Alert>;

  if (!groups) {
    return null;
  }

  const dataModelProperties = (groups || []).flatMap((group) => {
    return group.properties.map((property) => property.path);
  });

  const values = getValues(namePrefix);

  const customProperties = toPairs(values).filter(
    (val) => val[1]?.length > 0 && !dataModelProperties.includes(val[0].replace(/ /g, ".")),
  );

  return (
    <>
      {(groups || []).map((group) => {
        const propertyShapes = (
          <div key={"div" + group.groupIri} className="flex column g-7">
            {group.properties.map((property) => {
              return (
                <PropertyShape
                  key={group.groupIri + property.path}
                  propertyModel={property}
                  name={(namePrefix + "." + property.path.replace(/\./g, " ")) as `properties.${string}`}
                />
              );
            })}
          </div>
        );

        if (group.groupIri === NO_GROUP) {
          return propertyShapes;
        }

        return (
          <PropertyGroup key={"group" + group.groupIri} groupName={group.groupName}>
            <div className="px-3">{propertyShapes}</div>
          </PropertyGroup>
        );
      })}
      {customProperties.length > 0 && (
        <PropertyGroup groupName="Properties outside the data model" gray>
          <div className="flex column g-7 px-3">
            {customProperties.map((customProperty) => {
              return (
                <PropertyShape
                  key={customProperty[0]}
                  propertyModel={{ path: customProperty[0].replace(/ /g, ".") }}
                  name={`${namePrefix}.${customProperty[0]}`}
                />
              );
            })}
          </div>
        </PropertyGroup>
      )}
    </>
  );
};

export default NodeShape;
