import { Chip } from "@mui/material";
import getClassName from "classnames";
import numeral from "numeral";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { FACETED_SEARCH_NULL_ALIAS } from "@triply/utils/Constants";
import type { SimpleDataset, SimpleOrganization, SimpleQuery, SimpleStory } from "@triply/utils/Models";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import { Avatar, FontAwesomeRoundIcon, HumanizedDate, StoryBadge } from "#components/index.ts";
import { getQueryIconForType } from "#helpers/FaIcons.tsx";
import type { Page } from "./index.tsx";
import * as styles from "./style.scss";

interface Props {
  type: Page;
  item: SimpleDataset | SimpleStory | SimpleQuery | SimpleOrganization;
}

const Badge: React.FC<{
  type: Page;
  item: SimpleDataset | SimpleStory | SimpleQuery | SimpleOrganization;
  link: string;
}> = ({ type, item, link }) => {
  switch (type) {
    case "datasets":
    case "organizations":
      return (
        <Avatar
          size="sm"
          avatarName={item.displayName || item.name}
          avatarUrl={"avatarUrl" in item ? item.avatarUrl : undefined}
          className={styles.avatar}
          linkTo={link}
          alt=""
        />
      );
    case "stories":
      return <StoryBadge bannerUrl={"bannerUrl" in item ? item.bannerUrl : undefined} />;
    case "queries":
      return "visualization" in item ? (
        <FontAwesomeRoundIcon icon={getQueryIconForType(item.visualization)} size="sm" aria-label="Query" />
      ) : null;
  }
};

const getLink = ({ type, item }: Props) => {
  switch (type) {
    case "datasets":
      return `/${"ownerAccountName" in item && item.ownerAccountName}/${item.name}`;
    case "stories":
    case "queries":
      return `/${"ownerAccountName" in item && item.ownerAccountName}/-/${type}/${item.name}`;
    case "organizations":
      return `/${item.name}`;
  }
};

const ListItem: React.FC<Props> = ({ type, item }) => {
  const history = useHistory();
  const link = getLink({ type, item });
  return (
    <div className={getClassName("flex center rounding", styles.listItem)}>
      <div className="" aria-hidden="true">
        <Badge type={type} item={item} link={link} />
      </div>
      <div className="grow ellipsis">
        <Link to={link} className="ellipsis">
          {item.displayName || item.name}
        </Link>
        <div className="ellipsis">
          <small className={styles.details}>
            {"ownerAccountName" in item && (
              <span key="owner">
                by{" "}
                <Link to={`/${item.ownerAccountName}`} className={styles.ownerLink}>
                  {item.ownerAccountDisplayName || item.ownerAccountName}
                </Link>
              </span>
            )}
            {"members" in item && <span>{item.members === 1 ? "1 member" : `${item.members} members`}</span>}
            {"lastGraphsUpdateTime" in item && item.lastGraphsUpdateTime && (
              <span key="updated">
                {"updated "}
                <HumanizedDate date={item.lastGraphsUpdateTime} />
              </span>
            )}

            <span key="created">
              {"created "}
              <HumanizedDate date={item.createdAt} />
            </span>

            {"access" in item && (
              <span>
                <AccessLevelIcon key="access" level={item.access} type="dataset" />
              </span>
            )}
            {"license" in item && item.license !== FACETED_SEARCH_NULL_ALIAS && <span>{item.license}</span>}
          </small>
        </div>
      </div>
      <Chip
        label="SPARQL"
        variant="outlined"
        size="small"
        color="secondary"
        onClick={() => {
          history.push(`${link}/sparql`);
        }}
        className={styles.hide1}
      />
      {"search" in item && item.search === "true" && (
        <Chip
          label="Elasticsearch"
          variant="outlined"
          size="small"
          color="success"
          onClick={() => {
            history.push(`${link}/search`);
          }}
          className={styles.hide1}
        />
      )}
      {"statements" in item && (
        <Chip
          label={numeral(item.statements).format("0,0a")}
          title={`${numeral(item.statements).format("0,0")} statements`}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => {
            history.push(link);
          }}
          className={styles.hide2}
        />
      )}
    </div>
  );
};
export default ListItem;
