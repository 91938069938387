import { Accordion, AccordionDetails, AccordionSummary, Alert, Skeleton, Typography } from "@mui/material";
import { groupBy, toPairs } from "lodash-es";
import * as React from "react";
import { Link } from "react-router-dom";
import { stringifyQuery } from "@core/utils";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useSparqlRaw from "#helpers/hooks/useSparqlRaw.ts";
import { FontAwesomeIcon } from "../../components";
import * as styles from "./styles/index.scss";

const factory = factories.compliant;
const Outlinks: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();

  const { data, error, loading } = useSparqlRaw<[{ p: string; o: string }?]>(
    currentClass &&
      `
    select ?p ?o where {
      bind(${termToString(factory.namedNode(currentClass))} as ?currentClass)
      ?currentClass ?p ?o.
    }
    group by ?p ?o
    limit 100
    `,
  );
  const applyPrefixes = useApplyPrefixes();

  if (error) return <Alert severity="warning">Outlinks could not be loaded.</Alert>;

  if (loading) return <Skeleton height={54} variant="rectangular" />;

  if (!currentClass || !data || !data.results.bindings[0]) return null;

  const grouped = toPairs(groupBy(data.results.bindings, "p.value"));

  return (
    <dl>
      {grouped.map(([predicate, objects]) => (
        <React.Fragment key={predicate}>
          <dt
            draggable
            onDragStart={(e) => e.dataTransfer.setData("text/plain", predicate)}
            className={styles.grabable}
          >
            {applyPrefixes(predicate)}
          </dt>
          <dd>
            {objects.map((object, i) => {
              return (
                <div key={"" + object?.o?.value + i}>
                  {object?.o?.type === "uri" ? (
                    <Link
                      title={object.o.value}
                      to={{
                        pathname: `./browser`,
                        search: stringifyQuery({ resource: object.o.value }),
                      }}
                      draggable
                      onDragStart={(e) => e.dataTransfer.setData("text/plain", object.o!.value)}
                    >
                      {applyPrefixes(object.o.value)}
                    </Link>
                  ) : (
                    applyPrefixes(object?.o?.value)
                  )}
                </div>
              );
            })}
          </dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

const Inlinks: React.FC<{}> = ({}) => {
  const currentClass = useCurrentResource();

  const { data, error, loading } = useSparqlRaw<[{ s: string; p: string }?]>(
    currentClass &&
      `
    prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    select ?s ?p where {
      bind(<${currentClass}> as ?currentClass)
      ?s ?p ?currentClass.
      filter(?p != rdf:type)
    }
    group by ?s ?p
    limit 100
    `,
  );
  const applyPrefixes = useApplyPrefixes();

  if (error) return <Alert severity="warning">Inlinks could not be loaded.</Alert>;

  if (loading) return <Skeleton />;

  if (!currentClass || !data || !data.results.bindings[0]) return null;

  const grouped = toPairs(groupBy(data.results.bindings, "p.value"));

  return (
    <dl>
      {grouped.map(([predicate, objects]) => (
        <React.Fragment key={predicate}>
          <dt
            draggable
            onDragStart={(e) => e.dataTransfer.setData("text/plain", predicate)}
            className={styles.grabable}
          >
            <FontAwesomeIcon icon="arrow-left" className="mr-2" />
            {applyPrefixes(predicate)}
          </dt>
          <dd>
            {objects.map((object, i) => {
              return (
                <div key={"" + object?.s?.value + i}>
                  {object?.s?.type === "uri" ? (
                    <Link
                      to={{
                        pathname: `./browser`,
                        search: stringifyQuery({ resource: object.s.value }),
                      }}
                      draggable
                      onDragStart={(e) => e.dataTransfer.setData("text/plain", object.o!.value)}
                    >
                      {applyPrefixes(object.s.value)}
                    </Link>
                  ) : (
                    applyPrefixes(object?.s?.value)
                  )}
                </div>
              );
            })}
          </dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

const Links: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Accordion variant="outlined" className={styles.block}>
      <AccordionSummary expandIcon={<FontAwesomeIcon size="lg" icon={["fas", "caret-down"]} />}>
        <Typography variant="h6" component="h2">
          Properties of {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Outlinks />
        <Inlinks />
      </AccordionDetails>
    </Accordion>
  );
};

export default Links;
