import { Button } from "@mui/material";
import * as React from "react";
import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";
import { stringifyQuery } from "@core/utils";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import fetch from "#helpers/fetch.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useRemovePrefixes from "#helpers/hooks/useRemovePrefixes.ts";
import useSparql from "#helpers/hooks/useSparql.ts";
import { ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../components";
import useConstructConsoleUrl from "../../helpers/hooks/useConstructConsoleUrl";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import ClassForm from "./Forms/Class";
import type { ClassData } from "./Forms/Types";

const factory = factories.compliant;

export type CurrentClass = {
  label?: string;
  iri: string;
  shapeIri?: string;
  description?: string;
  order: number;
  parent: {
    id: string;
    label?: string;
    description?: string;
  } | null;
};

const EditClass: React.FC<{}> = () => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const currentDs = useCurrentDataset()!;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/sparql`,
    fromBrowser: true,
  });
  const datasetUrl = useConstructConsoleUrl()({ pathname: `/${datasetPath}` });
  const history = useHistory();
  const dispatch = useDispatch();
  const removePrefixes = useRemovePrefixes();
  const currentClass = useCurrentResource();

  const prefixes = useDatasetPrefixes();
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "shp")?.iri || `${datasetUrl}/shp/`;

  const onClose = React.useCallback(() => {
    isDirty ? setConfirmationDialogOpen(true) : setOpen(false);
  }, [isDirty]);

  const { data: currentData, loading } = useSparql<CurrentClass>(getClassInfoQuery(currentClass), {
    singularizeVariables: { "": true, parent: true },
  });
  return (
    <>
      <Button
        color="secondary"
        aria-label="Edit class"
        size="small"
        disabled={loading || !currentData?.shapeIri}
        onClick={() => setOpen(true)}
        startIcon={<FontAwesomeIcon icon="pencil" />}
      >
        Edit
      </Button>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now all changes will be lost"
      />
      {currentData && (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth title="Edit class" closeButton>
          <div className="px-5 pb-5">
            <ClassForm
              initialValues={currentData}
              datasetUrl={datasetUrl}
              onDirty={setDirty}
              onSubmit={async (classUpdate: ClassData) => {
                const shpIri = factory.namedNode(currentData.shapeIri || `${baseIri}c-${uuid()}`);
                const newIri = factory.namedNode(removePrefixes(classUpdate.iri.trim()));
                const newParent = classUpdate.parent
                  ? typeof classUpdate.parent === "string"
                    ? factory.namedNode(removePrefixes(classUpdate.parent?.trim()))
                    : factory.namedNode(classUpdate.parent.id)
                  : undefined;
                const oldParent = currentData.parent?.id ? factory.namedNode(currentData.parent.id) : undefined;
                const labelChanged = classUpdate.label !== currentData.label;
                const commentChanged = classUpdate.description !== currentData.description;
                const orderChanged = classUpdate.order !== currentData.order;
                const parentChanged =
                  (newParent && !newParent.equals(oldParent)) || (oldParent && !oldParent.equals(newParent));
                const iriChanged = newIri.value !== currentData.iri;
                const oldLabelTerm =
                  currentData.label?.trim() && termToString(factory.literal(currentData.label?.trim() || ""));
                const oldCommentTerm =
                  currentData.description?.trim() &&
                  termToString(factory.literal(currentData.description?.trim() || ""));
                const query = `
                prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
                prefix owl: <http://www.w3.org/2002/07/owl#>
                prefix sh: <http://www.w3.org/ns/shacl#>
                delete {
                  ?oldDef
                  rdfs:label ?oldLabel;
                  rdfs:comment ?oldComment;
                  rdfs:subClassOf ?oldParent.
                  ?shp sh:order ?oldOrder.
                  ?oldDef ?p ?o .
                  ?propertyShape sh:class ?oldDef .
                  ?child rdfs:subClassOf ?oldDef .
                  ?nodeShape sh:targetClass ?oldDef .
                }
                insert {
                  ?newDef
                  rdfs:label ?newLabel;
                  rdfs:comment ?newComment;
                  rdfs:subClassOf ?newParent.
                  ?shp sh:order ?newOrder.
                  ?newDef ?p ?o .
                  ?propertyShape sh:class ?newDef .
                  ?child rdfs:subClassOf ?newDef .
                  ?nodeShape sh:targetClass ?newDef .
                }
                where {
                  bind(${termToString(factory.namedNode(currentClass))} as ?oldDef)

                  # always set \`newDef\`, even if it is the same as \`oldDef\`
                  bind(${termToString(newIri)} as ?newDef)
                  bind(${termToString(shpIri)} as ?shp)

                  # if a value changed, add the **new** value here
                  # else if the IRI changed, add the old value here
                  # else undef
                  values (?newLabel ?newComment ?newParent ?newOrder) {
                          (
                            ${labelChanged && classUpdate.label?.trim() ? termToString(factory.literal(classUpdate.label?.trim() || "")) : iriChanged && oldLabelTerm ? oldLabelTerm : "UNDEF"}
                            ${commentChanged && classUpdate.description?.trim() ? termToString(factory.literal(classUpdate.description?.trim() || "")) : iriChanged && oldCommentTerm ? oldCommentTerm : "UNDEF"}
                            ${parentChanged && newParent ? termToString(newParent) : iriChanged && oldParent ? termToString(oldParent) : "UNDEF"}
                            ${orderChanged && classUpdate.order ? termToString(factory.literal(classUpdate.order, factory.namedNode("http://www.w3.org/2001/XMLSchema#decimal"))) : "UNDEF"}
                          )
                          }

                  # if a value changed, add the **old** value here
                  # else if the IRI changed, add the old value here
                  # else undef
                  values (?oldLabel ?oldComment ?oldParent ?oldOrder) {
                          (
                            ${oldLabelTerm && (labelChanged || iriChanged) ? oldLabelTerm : "UNDEF"}
                            ${oldCommentTerm && (commentChanged || iriChanged) ? oldCommentTerm : "UNDEF"}
                            ${oldParent && (parentChanged || iriChanged) ? termToString(oldParent) : "UNDEF"}
                            ${orderChanged && classUpdate.order ? termToString(factory.literal(classUpdate.order, factory.namedNode("http://www.w3.org/2001/XMLSchema#decimal"))) : "UNDEF"}
                          )
                          }

                  optional {
                    { ?oldDef ?p ?o
                    filter(?p not in (rdfs:label, rdfs:comment, rdfs:subClassOf)) } union {
                      ?propertyShape sh:class ?oldDef
                    } union {
                      ?child rdfs:subClassOf ?oldDef
                    } union {
                      ?nodeShape sh:targetClass ?oldDef
                    }
                    filter(?oldDef != ?newDef)  # only return results if the IRI was changed
                  }
};              `;

                const body = new FormData();
                body.set("update", query);

                await fetch(updateUrl, {
                  credentials: "same-origin",
                  method: "POST",
                  body: body,
                });

                setOpen(false);

                await Promise.all([
                  dispatch<typeof refreshDatasetsInfo>(
                    refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
                  ),
                  dispatch<typeof getGraphs>(
                    getGraphs({
                      accountName: currentDs.owner.accountName,
                      datasetName: currentDs.name,
                      datasetId: currentDs.id,
                    }),
                  ),
                ]);
                if (iriChanged) {
                  history.replace({
                    search: stringifyQuery({ resource: newIri.value }),
                  });
                }
              }}
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export default EditClass;

function getClassInfoQuery(currentClass: string) {
  return `
# GetClassInfoQuery
prefix sh: <http://www.w3.org/ns/shacl#>
prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>

select distinct * where {
  bind(${termToString(factory.namedNode(currentClass))} as ?iri)
  ?shapeIri sh:targetClass ?iri;
  optional {
    ?iri rdfs:label ?label.
  }
  optional {
    ?shapeIri sh:order ?order
  }
  optional {
    ?iri rdfs:comment ?description
  }
  optional {
    ?iri rdfs:subClassOf ?parent_id.
    optional {
      ?parent_id rdfs:label ?parent_label
    }
    optional {
      ?parent_id rdfs:comment ?parent_description
    }
  }
}
`;
}
