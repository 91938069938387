import { NoSsr } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { urlInfoToString } from "@core/utils";
import type { Models } from "@triply/utils";
import { DatasetMetadata, ErrorPage } from "#components/index.ts";
import {
  getIdeVisualization,
  yasguiVisualizationConfigToIdeVisualizationConfig,
} from "#components/Sparql/SparqlUtils.ts";
import { FooterContext } from "#containers/NavConsole/index.tsx";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import useRunningServices from "#helpers/hooks/useRunningServices.ts";
import { useCurrentAccount } from "#reducers/app.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import SparqlIDE from "./SparqlIDE.tsx";
import type { PersistedGlobalIdeConfig, TabQuery } from "./useSparqlIDEContext.tsx";
import {
  DEFAULT_QUERY,
  getEndpointNameFromUrl,
  getNewExpiryDate,
  SparqlIDEContextProvider,
  storeConfig,
} from "./useSparqlIDEContext.tsx";

const SparqlIdeComponent: React.FC = () => {
  const constructUrlToApi = useConstructUrlToApi();
  const location = useLocation();
  const currentAccount = useCurrentAccount();
  const currentDs = useCurrentDataset();
  const staticConfig = useSelector((state: GlobalState) => state.config.staticConfig);
  const apiUrl = useSelector((state: GlobalState) => state.config.staticConfig?.apiUrl);
  const prefixes = useDatasetPrefixes();
  const dsUrl = constructUrlToApi({
    pathname: `/datasets/${currentDs!.owner.accountName}/${currentDs!.name}`,
  });
  const { hideFooter } = React.useContext(FooterContext);
  React.useEffect(() => {
    hideFooter(true);
    return () => hideFooter(false);
  }, [hideFooter]);
  const services = useRunningServices("sparql");
  const datasetSegment = `${currentDs!.owner.accountName}/${currentDs!.name}`;
  const datasetId = currentDs?.id;
  const availableServices = React.useMemo(() => {
    const serviceList: { name: string; endpoint: string; id: string; type: Models.SparqlQueryServiceType }[] =
      services.map((service) => ({
        name: service.name,
        endpoint: service.endpoint!,
        id: service.id,
        type: service.type as Models.SparqlQueryServiceType,
      }));
    return [
      ...serviceList,
      {
        name: "Speedy",
        id: `${datasetId}-speedy`,
        endpoint: urlInfoToString(apiUrl, {
          pathname: `/datasets/${datasetSegment}/sparql`,
        }),
        type: "speedy",
      } as const,
    ];
  }, [apiUrl, datasetSegment, services, datasetId]);

  // Yasgui to Sparql IDE local storage migration
  React.useEffect(() => {
    const sparqlIdeConfig = localStorage.getItem("Sparql-IDE");
    if (currentDs) {
      let parsedJson: PersistedGlobalIdeConfig | undefined = undefined;
      if (sparqlIdeConfig) {
        try {
          parsedJson = JSON.parse(sparqlIdeConfig);
        } catch (e) {
          // No action taken in the case of an exception
        }
      }
      // Checking if there is an existing overall Sparql IDE config or an existing dataset ide config
      if (!sparqlIdeConfig || (parsedJson && parsedJson[currentDs.id] === undefined)) {
        // Checking and fetching yasgui configurations
        let yasguiConfigs: any[] = [];
        const speedyYasguiConfig = localStorage.getItem(`yasgui${currentDs.id}-speedy_config`);
        if (speedyYasguiConfig) {
          yasguiConfigs.push(JSON.parse(speedyYasguiConfig));
          localStorage.removeItem(`yasgui${currentDs.id}-speedy_config`);
        }
        services.forEach((service) => {
          const serviceYasguiConfig = localStorage.getItem(`yasgui${service.id}_config`);
          if (serviceYasguiConfig) {
            yasguiConfigs.push(JSON.parse(serviceYasguiConfig));
            localStorage.removeItem(`yasgui${service.id}_config`);
          }
        });

        //If yasgui configs are present, we will proceed to migrate them.
        if (yasguiConfigs.length > 0) {
          //Sorting yasgui configs by time created in order to determine the earliest created tab
          yasguiConfigs.sort((a, b): number => {
            if (a.time < b.time) return -1;
            if (a.time > b.time) return 1;
            return 0;
          });
          //Migrating yasgui configs to Sparql IDE configs
          let overallTabOrder: string[] = [];
          let tabQueriesConfig: { [id: string]: TabQuery } = {};
          yasguiConfigs.forEach((config) => {
            config.val.tabs.forEach((tab: string) => {
              overallTabOrder.push(tab);
              tabQueriesConfig[tab] = {
                name: config.val.tabConfig[tab].name || "Query",
                query: config.val.tabConfig[tab].yasqe.value || DEFAULT_QUERY,
                endpointName: getEndpointNameFromUrl(config.val.tabConfig[tab].requestConfig.endpoint),
                visualization: getIdeVisualization(config.val.tabConfig[tab].yasr.settings.selectedPlugin) || "Table",
                visualizationConfig: yasguiVisualizationConfigToIdeVisualizationConfig(
                  config.val.tabConfig[tab].yasr.settings.selectedPlugin || "table",
                  config.val.tabConfig[tab].yasr.settings.pluginsConfig[
                    config.val.tabConfig[tab].yasr.settings.selectedPlugin
                  ] || undefined,
                  staticConfig,
                ),
              };
            });
          });
          if (datasetId) {
            storeConfig({
              [datasetId]: {
                selectedTab: overallTabOrder[0],
                tabOrder: overallTabOrder,
                queries: tabQueriesConfig,
                exp: getNewExpiryDate(),
              },
            });
          }
        }
      }
    }
  }, [currentDs, datasetId, services, staticConfig]);

  if (!currentAccount || !currentDs) {
    return <ErrorPage statusCode={404} />;
  }

  return (
    <>
      <DatasetMetadata
        currentPath={location.pathname}
        currentAccount={currentAccount}
        currentDs={currentDs}
        title={"SPARQL IDE"}
      />
      {/* IDE Context on encapsulates IDE Component and is not accessible elsewhere */}
      <NoSsr>
        <SparqlIDEContextProvider dataset={currentDs.id}>
          <SparqlIDE datasetUrl={dsUrl} prefixes={prefixes} availableServices={availableServices} />
        </SparqlIDEContextProvider>
      </NoSsr>
    </>
  );
};

export default SparqlIdeComponent;
