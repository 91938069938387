import * as React from "react";
import { useLocation } from "react-router";
import { factories, getWriter, parse, Store } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import { Button, ConfirmationDialog, FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { parseSearchString } from "../../helpers/utils";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";

const factory = factories.compliant;
const RemoveResource: React.FC<{}> = () => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const jobsUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/jobs`,
    fromBrowser: true,
  });
  const statementsUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/statements.trig`,
    query: { limit: "10000" },
    fromBrowser: true,
  });
  const graphsUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/graphs`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const resource = parseSearchString(useLocation().search).resource as string;

  return (
    <>
      <Button
        color="error"
        elevation
        onClick={() => setOpen(true)}
        title="Remove this resource from the dataset"
        startIcon={<FontAwesomeIcon icon="trash" />}
        size="small"
      >
        Remove resource
      </Button>
      <ConfirmationDialog
        open={open}
        loading={saving}
        onClose={() => setOpen(false)}
        actionLabel="Remove resource"
        title="Remove this resource?"
        onConfirm={async () => {
          setSaving(true);

          const currentData = await fetch(statementsUrl, {
            credentials: "same-origin",
          }).then((response) => response.text());

          const store = new Store();

          const quads = parse(currentData, { format: "trig" });
          store.addQuads(quads);

          await new Promise((resolve, reject) => {
            store.removeMatches(factory.namedNode(resource)).on("end", resolve).on("error", reject);
          });
          await new Promise((resolve, reject) => {
            store.removeMatches(null, null, factory.namedNode(resource)).on("end", resolve).on("error", reject);
          });

          const writer = getWriter({ format: "trig" });

          writer.addQuads(store.getQuads({}));

          const serialized: string = await new Promise((resolve, reject) => {
            writer.end((error: Error | undefined, result: string) => {
              if (error) reject(error);
              resolve(result);
            });
          });

          const body = new FormData();
          body.set("file", new Blob([serialized]), "data.trig");

          await fetch(graphsUrl, {
            credentials: "same-origin",
            method: "DELETE",
          });
          await fetch(jobsUrl, {
            credentials: "same-origin",
            method: "POST",
            body: body,
          });

          await dispatch<typeof refreshDatasetsInfo>(
            refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
          );
          await dispatch<typeof getGraphs>(
            getGraphs({
              accountName: currentDs.owner.accountName,
              datasetName: currentDs.name,
              datasetId: currentDs.id,
            }),
          );

          setSaving(false);
          setOpen(false);
        }}
        description={`Are you sure you want to remove '${resource}' from the dataset?`}
      />
    </>
  );
};

export default RemoveResource;
