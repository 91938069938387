import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { validation } from "@core/utils";
import type { Models, Prefix } from "@triply/utils";
import { getPrefixed } from "@triply/utils/prefixUtils";
import { FontAwesomeIcon, FormField, LoadingButton } from "../../../components";
import AllowedValues from "./AllowedValues";
import AutosuggestTerm from "./AutosuggestTerm";
import SimpleAutoSuggest from "./SimpleAutosuggest";
import * as styles from "./style.scss";

export type VariableType = "NamedNode" | "LanguageStringLiteral" | "StringLiteral" | "TypedLiteral";

const varnameValidator = validation.toStringValidator(validation.sparqlVarnameValidations);
const languageTagValidator = validation.toStringValidator(validation.languageTagValidations);
const iriValidator = validation.toStringValidator(validation.iriValidations);

export interface FormValues {
  name: string;
  defaultValue?: string;
  required?: boolean;
  variableType: VariableType;
  datatype?: string;
  language?: string;
  allowedValues?: string[];
}

interface Props {
  initialValues?: FormValues;
  variables?: string[];
  datasetPath: string | undefined;
  onCancel: () => void;
  onSubmit: (values: FormValues) => void;
  variableDefinitions: Models.VariableConfig[] | undefined;
  prefixes: Prefix[];
}

const DATA_TYPES: string[] = [
  "http://www.w3.org/2001/XMLSchema#boolean",
  "http://www.w3.org/2001/XMLSchema#date",
  "http://www.w3.org/2001/XMLSchema#dateTime",
  "http://www.w3.org/2001/XMLSchema#double",
  "http://www.w3.org/2001/XMLSchema#float",
  "http://www.w3.org/2001/XMLSchema#gYear",
  "http://www.w3.org/2001/XMLSchema#int",
  "http://www.w3.org/2001/XMLSchema#integer",
  "http://www.w3.org/2001/XMLSchema#string",
];

export const formValuesToQueryVar = (values: FormValues): Models.VariableConfig => {
  if (values.variableType === "NamedNode") {
    return {
      name: values.name,
      termType: "NamedNode",
    };
  } else {
    const base: Models.VariableConfig = {
      name: values.name,
      termType: "Literal",
    };
    if (values.variableType === "LanguageStringLiteral") {
      base.language = values.language;
    }
    if (values.variableType === "TypedLiteral") {
      base.datatype = values.datatype;
    }
    return base;
  }
};
function getUsedVars(
  vars: string[] | undefined,
  variableDefinitions: Models.VariableConfig[] | undefined,
  currentName: string | undefined,
) {
  if (!vars) return [];
  if (!variableDefinitions) return [];
  return vars.filter((v) => v !== currentName && variableDefinitions.find((def) => def.name === v));
}

const QueryVariables: React.FC<Props> = ({
  initialValues,
  variables,
  variableDefinitions,
  datasetPath,
  onCancel,
  onSubmit,
  prefixes,
}) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isValid, isSubmitting },
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      required: false,
      variableType: "StringLiteral",
      allowedValues: [],
      datatype: "",
      language: "",
      defaultValue: "",
      ...initialValues,
    },
    mode: "all",
  });
  const usedVariables = React.useMemo(
    () => getUsedVars(variables, variableDefinitions, initialValues?.name),
    [variables, variableDefinitions, initialValues?.name],
  );
  const validateQueryVarValue = React.useCallback(
    (value: string, all: FormValues) => {
      const nameValidation = varnameValidator(value);
      if (nameValidation) return nameValidation;
      const alreadyUsed = value !== initialValues?.name && variableDefinitions?.find((def) => value === def.name);
      if (alreadyUsed) return `Variable "${value}" is already defined`;
    },
    [initialValues?.name, variableDefinitions],
  );
  const watchRequired = watch("required", !!initialValues?.required);
  const watchDatatype = watch("datatype", initialValues?.datatype || "");
  const watchType = watch("variableType", initialValues?.variableType);
  const watchLanguage = watch("language", initialValues?.language);
  const watchAllowedValues = watch("allowedValues", initialValues?.allowedValues || []);

  const nameId = React.useId();
  const typeId = React.useId();
  const languageId = React.useId();
  const datatypeId = React.useId();
  const defaultLabelId = React.useId();
  const defaulValueId = React.useId();
  const allowedValueId = React.useId();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={getClassName("px-5 pb-5 flex", styles.form)}>
      <FormField label="Variable name" className="mt-5 mb-6" inputId={nameId}>
        <Controller
          name="name"
          control={control}
          rules={{ validate: validateQueryVarValue }}
          render={({ field, fieldState: { error } }) => (
            <SimpleAutoSuggest
              {...field}
              id={nameId}
              autoFocus
              className={styles.varNameAutocomplete}
              options={variables || []}
              clearIcon={false}
              error={error?.message}
              startAdornment="?"
              endAdornment={
                field.value !== "" && !variables?.includes(field.value) ? (
                  <span title="This variable is not present in the query" color="warning">
                    <FontAwesomeIcon icon="exclamation-triangle" className={styles.warning} />
                  </span>
                ) : null
              }
              disabledOptions={usedVariables}
            />
          )}
        />
      </FormField>
      <FormField label="Type of variable" className="mb-6" id={typeId}>
        <Controller
          name="variableType"
          control={control}
          rules={{ required: "The type of a variable is required." }}
          render={({ field, fieldState: { error } }) => (
            <Select
              {...field}
              onChange={(e) => {
                field.onChange(e);
                trigger(["allowedValues", "defaultValue"]).catch(() => {});
              }}
              labelId={typeId}
              error={!!error}
              required
              className={styles.variableType}
            >
              <MenuItem value="NamedNode">IRI</MenuItem>
              <MenuItem value="StringLiteral">String Literal</MenuItem>
              <MenuItem value="LanguageStringLiteral">Literal with language tag</MenuItem>
              <MenuItem value="TypedLiteral">Literal with datatype</MenuItem>
            </Select>
          )}
        />
      </FormField>

      {watchType === "LanguageStringLiteral" && (
        <FormField label="Language" className="mb-6" inputId={languageId}>
          <Controller
            name="language"
            control={control}
            disabled={watchType !== "LanguageStringLiteral"}
            rules={{ validate: (value) => languageTagValidator(value) }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                id={languageId}
                onChange={(e) => {
                  field.onChange(e);
                  trigger(["defaultValue", "allowedValues"]).catch(() => {});
                }}
                className={styles.language}
                InputProps={{ startAdornment: <InputAdornment position="start">@</InputAdornment> }}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormField>
      )}
      {watchType === "TypedLiteral" && (
        <FormField label="Datatype" className="mb-6" inputId={datatypeId}>
          <Controller
            control={control}
            name="datatype"
            disabled={watchType !== "TypedLiteral"}
            rules={{ validate: (value) => iriValidator(value), required: true }}
            render={({ field, fieldState: { error } }) => (
              <SimpleAutoSuggest
                {...field}
                id={datatypeId}
                onChange={(value) => {
                  field.onChange(value);
                  trigger(["allowedValues", "defaultValue", "datatype", "language"]).catch(() => {});
                }}
                className={styles.dataType}
                value={field.value || ""}
                options={DATA_TYPES}
                error={error?.message}
                startAdornment={<InputAdornment position="start">^^&lt;</InputAdornment>}
                endAdornment={<InputAdornment position="end">&gt;</InputAdornment>}
              />
            )}
          />
        </FormField>
      )}
      <FormField label="" className="mb-6">
        <FormControlLabel
          label="Variable is required"
          labelPlacement="end"
          control={
            <Controller
              name="required"
              control={control}
              render={({ field }) => <Checkbox {...field} checked={field.value} />}
            />
          }
        ></FormControlLabel>
      </FormField>
      {!watchRequired && datasetPath && (
        <FormField
          label="Default value"
          className="mb-6"
          id={defaultLabelId}
          inputId={!(watchAllowedValues && watchAllowedValues.length > 0) ? defaulValueId : undefined}
        >
          <Controller
            control={control}
            name="defaultValue"
            rules={{
              validate: (value, allValues) => {
                if (
                  value !== "" &&
                  value !== undefined &&
                  allValues.allowedValues &&
                  allValues.allowedValues.length > 0
                ) {
                  if (allValues.allowedValues && !allValues.allowedValues.includes(value))
                    return `"${value}" is not in list of allowed values`;
                }
                return validation.toStringValidator(validation.getQueryVarValidations(formValuesToQueryVar(allValues)))(
                  value,
                );
              },
            }}
            render={({ field, fieldState: { error } }) => {
              if (watchAllowedValues && watchAllowedValues.length > 0) {
                return (
                  <>
                    <Select
                      {...field}
                      error={!!error}
                      className={styles.defaultValue}
                      displayEmpty
                      labelId={defaultLabelId}
                      size="small"
                      renderValue={(value) =>
                        value === "" ? (
                          <i>None</i>
                        ) : watchType === "NamedNode" ? (
                          getPrefixed(value, prefixes) || value
                        ) : (
                          value
                        )
                      }
                    >
                      {field.value && !watchAllowedValues.includes(field.value) && (
                        <MenuItem value={field.value} disabled>
                          <ListItemText
                            primary={
                              watchType === "NamedNode"
                                ? getPrefixed(field.value, prefixes) || field.value
                                : field.value
                            }
                            secondary={watchType === "NamedNode" && !!getPrefixed(field.value, prefixes) && field.value}
                          />
                        </MenuItem>
                      )}
                      {watchAllowedValues.map((value) => (
                        <MenuItem value={value} key={value}>
                          <ListItemText
                            primary={watchType === "NamedNode" ? getPrefixed(value, prefixes) || value : value}
                            secondary={watchType === "NamedNode" && !!getPrefixed(value, prefixes) && value}
                          />
                        </MenuItem>
                      ))}
                      <MenuItem value="">
                        <i>None</i>
                      </MenuItem>
                    </Select>
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </>
                );
              }
              return (
                <AutosuggestTerm
                  {...field}
                  id={defaulValueId}
                  datasetPath={datasetPath}
                  className={styles.defaultValue}
                  variableType={watchType}
                  datatype={watchDatatype}
                  language={watchLanguage}
                  error={error?.message}
                  prefixes={prefixes}
                />
              );
            }}
          />
        </FormField>
      )}
      {datasetPath && (
        <FormField label="Allowed values" className="mb-6" inputId={allowedValueId}>
          <Controller
            control={control}
            name="allowedValues"
            rules={{
              validate: (value, allValues) => {
                const validator = validation.toStringValidator(
                  validation.getQueryVarValidations(formValuesToQueryVar(allValues)),
                );
                if (!value) return;
                for (const val of value) {
                  const validation = validator(val);
                  if (validation) {
                    return `"${val}": ${validation}`;
                  }
                }
              },
            }}
            render={({ field, fieldState: { error } }) => {
              return (
                <AllowedValues
                  {...field}
                  id={allowedValueId}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("defaultValue").catch(() => {});
                  }}
                  value={field.value || []}
                  datasetPath={datasetPath}
                  variableType={watchType}
                  datatype={watchDatatype}
                  language={watchLanguage}
                  error={error?.message}
                  prefixes={prefixes}
                />
              );
            }}
          />
        </FormField>
      )}
      <div className="flex">
        <LoadingButton
          className="mt-2"
          type="submit"
          color="secondary"
          disabled={!isValid || !isDirty}
          loading={isSubmitting}
        >
          {initialValues ? "Update" : "Add"} variable
        </LoadingButton>

        <Button onClick={onCancel} className="ml-2 mt-2" variant="text">
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default QueryVariables;
