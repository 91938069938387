import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { validation } from "@core/utils";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { Alert, FormField, Label, MuiTextFieldRedux } from "#components/index.ts";

const emailValidator = validation.toStringValidator(validation.emailValidations);

namespace ContactEmail {
  export interface FormData {
    contactEmail: string;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {}
}

const ContactEmail = ReduxForm.reduxForm<ContactEmail.FormData, ContactEmail.Props>({
  form: "contactEmail",
  validate: memoizee(
    (formData: ContactEmail.FormData) => {
      return {
        contactEmail: emailValidator(formData.contactEmail),
      };
    },
    { max: 10 },
  ),
})(
  class ContactEmailForm extends React.PureComponent<
    ContactEmail.Props & Partial<ReduxForm.InjectedFormProps<ContactEmail.FormData>>,
    any
  > {
    render() {
      const { handleSubmit, submitting, submitSucceeded, error, pristine, invalid } = this.props;

      return (
        <div>
          <form method="POST" onSubmit={handleSubmit}>
            <FormField breakPoint={250} label="Contact email" className="mt-6 mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                name="contactEmail"
                props={{
                  type: "email",
                  fullWidth: true,
                }}
                component={MuiTextFieldRedux}
              />
            </FormField>
            <Alert transparent message={error} />
            <div className="form-group mt-5">
              <LoadingButton
                type="submit"
                color="secondary"
                disabled={pristine || invalid}
                onClick={handleSubmit}
                loading={submitting}
              >
                Save
              </LoadingButton>
            </div>
          </form>
          {submitSucceeded && pristine && (
            <div className="mt-4">
              <Label success message="You have successfully updated the contact email." />
            </div>
          )}
        </div>
      );
    }
  } as any,
);

export default ContactEmail;
