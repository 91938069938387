import { NoSsr } from "@mui/material";
import * as React from "react";
import type { QueryResponse } from "#components/Sparql/SparqlUtils.ts";
import { isAskResponse } from "#components/Sparql/SparqlUtils.ts";
import { SparqlVisualizationContext, useSparqlResults } from "../../SparqlVisualizationContext";
import type { Renderer as RenderType } from "../displayUtils";
import Config from "./Config";
import Renderer from "./Renderer";
import * as styles from "./styles.scss";

export interface PluginConfig {
  displayType?: "Card" | "None";
  label?: {
    var: string;
    link?: string;
  };
  image?: {
    src: string;
    caption?: MixedMedia;
  };
  body?: MixedMedia;
}

export interface MixedMedia {
  key: string;
  renderer?: RenderType | "";
}

// Public function for data validity checking for Gallery
export function canDraw(data: QueryResponse | undefined): boolean {
  return !isAskResponse(data);
}

export interface Props {
  minimizeColumns?: boolean;
  reduceSpacing?: boolean;
}

const Gallery: React.FC<Props> = ({ minimizeColumns = false, reduceSpacing = false }) => {
  const { emptyResults } = useSparqlResults();
  const { registerDownload, unregisterDownload, setVisualizationConfig } = React.useContext(SparqlVisualizationContext);
  const contentContainerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (registerDownload && !emptyResults) {
      if (contentContainerRef.current) {
        registerDownload({
          id: "Gallery",
          displayName: "HTML",
          extension: "html",
          getDataUrl: () =>
            window.URL.createObjectURL(new Blob([contentContainerRef.current!.innerHTML], { type: "text/html" })),
        });
      }
      return () => {
        if (unregisterDownload) {
          unregisterDownload("Gallery");
        }
      };
    }
  }, [registerDownload, unregisterDownload, emptyResults]);

  return (
    <div ref={contentContainerRef} className={styles.galleryContainer}>
      <NoSsr>{setVisualizationConfig && <Config />}</NoSsr>
      <Renderer minimizeColumns={minimizeColumns} reduceSpacing={reduceSpacing} />
    </div>
  );
};

export default React.memo(Gallery);
