import { FormControlLabel, ListItemAvatar, ListItemText, MenuItem, Radio, Typography } from "@mui/material";
import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import { SubmissionError } from "redux-form";
import { validation } from "@core/utils";
import type { Models } from "@triply/utils";
import type { AccessLevel } from "@triply/utils/Models";
import AccessLevelIcon from "#components/AccessLevels/Icon.tsx";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import QueryMetaForm from "#components/Forms/QueryMeta/index.tsx";
import { substringMatch } from "#components/Highlight/index.tsx";
import type { MarkdownEditField, MuiAutosuggest } from "#components/index.ts";
import {
  Alert,
  Button,
  FontAwesomeIcon,
  FontAwesomeRoundIcon,
  FormField,
  Highlight,
  MarkdownEditFieldRedux,
  MaterialSelectRedux,
  MuiAutosuggestRedux,
  MuiTextFieldRedux,
} from "#components/index.ts";
import MaterialRadioButtonGroup, { Field } from "#components/MaterialRadioButtonGroup/index.tsx";
import type { MaterialSelect } from "#components/MaterialSelect/index.tsx";
import { getQueryIcon } from "#helpers/FaIcons.tsx";
import fetch from "#helpers/fetch.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { isStricterThan } from "../../../containers/Story/utils";

const storyElementParagraphValidator = validation.toStringValidator(validation.storyElementParagraphValidation);
const storyElementCaptionValidator = validation.toStringValidator(validation.storyElementCaptionValidation);

namespace StoryElementForm {
  export interface FormData {
    id?: string;
    caption: string;
    type: Models.StoryElementType;
    paragraph: string;
    query: Models.Query;
    queryVersion: number;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    className?: string;
    style?: React.CSSProperties;
    cancelFunction?: React.EventHandler<React.MouseEvent<any>>;
    updating?: boolean;
    querySearchUrl: string;
    datasetSearchUrl: string;
    storyAccessLevel: AccessLevel;
    createQuery: (values: QueryMetaForm.FormData) => Promise<any>;
    currentAccount: Account;
  }
  export interface State {
    type?: Models.StoryElementType | "newQuery";
    availableVersions?: number;
  }
}

function getQueryLabel(query: Models.Query) {
  return query.owner.accountName + " / " + query.name;
}

const StoryElementFormComponent: React.FC<StoryElementForm.Props> = (props) => {
  const {
    handleSubmit,
    updating,
    error,
    submitting,
    className,
    style,
    invalid,
    cancelFunction,
    datasetSearchUrl,
    currentAccount,
    storyAccessLevel,
    initialValues,
    change,
    querySearchUrl,
    createQuery,
  } = props;

  const [type, setType] = React.useState<Models.StoryElementType | "newQuery" | undefined>(initialValues?.type);
  const [availableVersions, setAvailableVersions] = React.useState<number | undefined>(
    initialValues?.query?.numberOfVersions,
  );

  function handleQueryChange(query: Models.Query) {
    change?.("query", query.id);
    change?.("queryVersion", undefined);
    setAvailableVersions(query.numberOfVersions);
  }

  const componentBasedFetch = memoizee(
    async (url: string) => {
      return fetch(url, { credentials: "same-origin" })
        .then((response) => {
          if (response.status === 200) return response.json() as Promise<Models.Query[]>;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    { primitive: true, promise: true },
  );

  function searchQuery(queryString: string) {
    const url = `${querySearchUrl}?q=${queryString}&limit=10`;
    return componentBasedFetch(url).then((results) => {
      if (results && Array.isArray(results)) return results;
      return [];
    });
  }

  function renderVersionOptions() {
    const versions = [
      <MenuItem key="latest" value="">
        Latest
      </MenuItem>,
    ];
    if (availableVersions) {
      for (let i = 1; i <= availableVersions; i++) {
        versions.push(
          <MenuItem key={`version ${i}`} value={i}>
            Version {i}
          </MenuItem>,
        );
      }
    }
    return versions;
  }

  function handleNewQuerySubmit(values: QueryMetaForm.FormData) {
    return createQuery(values)
      .then((response) => {
        const createdQuery = response.body;
        change?.("type", "query");
        change?.("query", createdQuery);
      })
      .then(() => (props as any).submit())
      .catch((e) => {
        throw new SubmissionError({ _error: e.message });
      });
  }
  return (
    <div className={getClassName("px-5 pb-5", className)} style={style}>
      <Field name="type" component={MaterialRadioButtonGroup}>
        <FormControlLabel
          value={"paragraph"}
          label={"Paragraph"}
          control={<Radio color="primary" onChange={() => setType("paragraph")} />}
        />
        <FormControlLabel
          value={"query"}
          label={"Existing query"}
          control={<Radio color="primary" onChange={() => setType("query")} />}
        />
        <FormControlLabel
          value={"newQuery"}
          label={"Create new query"}
          control={<Radio color="primary" onChange={() => setType("newQuery")} />}
        />
      </Field>

      {(type === "paragraph" || type === "query") && (
        <form onSubmit={handleSubmit}>
          {type === "paragraph" && (
            <ReduxForm.Field<ReduxForm.BaseFieldProps<MarkdownEditField.Props>>
              name="paragraph"
              props={{
                className: "pb-3 mt-6",
                label: "Paragraph",
                type: "text",
                fullWidth: true,
                multiline: true,
                rows: 15,
              }}
              component={MarkdownEditFieldRedux}
            />
          )}

          {type === "query" && (
            <>
              <FormField label="Query" className="mt-6 mb-6">
                <div className="flex">
                  <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiAutosuggest.Props<Models.Query, Models.Query>>>
                    name="query"
                    props={{
                      noResultsMessage: "No matching query.",
                      loadSuggestions: searchQuery,
                      clearOnSelect: false,
                      TextFieldProps: {
                        fullWidth: true,
                      },
                      transformInitialValueToSearchText: getQueryLabel,
                      onSuggestionSelected: (_e, data) => handleQueryChange(data.suggestion),
                      getSuggestionSearchText: getQueryLabel,
                      renderSuggestion: (query, suggestionParams) => {
                        return (
                          <MenuItem key={query.id} selected={suggestionParams.isHighlighted} component="div">
                            <ListItemAvatar>
                              <FontAwesomeRoundIcon
                                icon={getQueryIcon(query)}
                                size="sm"
                                aria-label={`Visualization type: ${
                                  query.renderConfig?.output === "gchart"
                                    ? query.renderConfig.settings?.chartType || "gchart"
                                    : query.renderConfig?.output || "unknown"
                                }`}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <>
                                  <Highlight
                                    fullText={query.displayName || query.name}
                                    highlightedText={suggestionParams.query}
                                    matcher={substringMatch}
                                  />
                                  <Typography className="mx-2" variant="body2" component="span">
                                    by
                                  </Typography>
                                  <Highlight
                                    fullText={query.owner.name || query.owner.accountName}
                                    highlightedText={suggestionParams.query}
                                    matcher={substringMatch}
                                  />
                                  <span title={query.accessLevel} className="ml-1">
                                    <AccessLevelIcon
                                      level={query.accessLevel}
                                      size="xs"
                                      type="query"
                                      className="mx-2"
                                    />
                                  </span>
                                </>
                              }
                              secondary={
                                <Highlight
                                  fullText={`${query.owner.accountName} / ${query.name}`}
                                  highlightedText={suggestionParams.query}
                                  matcher={substringMatch}
                                />
                              }
                            />
                            <ListItemText style={{ flexGrow: 0 }}>
                              <Typography variant="h6">
                                {query.serviceConfig.type !== "speedy" && !query.service && (
                                  <span title="The endpoint of this query is not accessible">
                                    <FontAwesomeIcon icon="exclamation-triangle" size="xs" className="mx-2" />
                                  </span>
                                )}
                                {isStricterThan(query.accessLevel, storyAccessLevel) && (
                                  <span title="This query has a more restrictive access level than the story">
                                    <FontAwesomeIcon icon="exclamation-triangle" size="xs" className="mx-2" />
                                  </span>
                                )}
                                {isStricterThan(query.dataset?.accessLevel, storyAccessLevel) && (
                                  <span title="The dataset connected to this query has a more restrictive access level than the story">
                                    <FontAwesomeIcon icon="exclamation-triangle" size="xs" className="mx-2" />
                                  </span>
                                )}
                              </Typography>
                            </ListItemText>
                          </MenuItem>
                        );
                      },
                    }}
                    component={MuiAutosuggestRedux}
                  />
                  <ReduxForm.Field<ReduxForm.BaseFieldProps<MaterialSelect.Props>>
                    name="queryVersion"
                    component={MaterialSelectRedux}
                    props={{
                      children: renderVersionOptions(),
                      classes: { root: "ml-3" },
                      displayEmpty: true,
                      disabled: availableVersions === undefined,
                    }}
                  />
                </div>
              </FormField>

              <FormField label="Caption" className="mb-7">
                <ReduxForm.Field<ReduxForm.BaseFieldProps<MuiTextFieldRedux.Props>>
                  name="caption"
                  component={MuiTextFieldRedux}
                  props={{
                    fullWidth: true,
                  }}
                />
              </FormField>
            </>
          )}

          <Alert transparent className="mt-5" message={error} />

          <div className={getClassName("form-group mt-6")}>
            <LoadingButton
              type="submit"
              color="secondary"
              disabled={invalid}
              onClick={handleSubmit}
              loading={submitting}
            >
              {updating ? "Update" : "Create"} story element
            </LoadingButton>

            {cancelFunction && (
              <Button variant="text" disabled={submitting} onClick={cancelFunction} className="mx-3">
                Cancel
              </Button>
            )}
          </div>
        </form>
      )}

      {type === "newQuery" && currentAccount.accountName && (
        <QueryMetaForm
          initialValues={{
            serviceType: "speedy",
            accessLevel: storyAccessLevel,
          }}
          onSubmit={handleNewQuerySubmit}
          cancelFunction={cancelFunction}
          datasetSearchUrl={datasetSearchUrl}
          style={{ padding: 0, paddingTop: 20 }}
          isNewQuery
          owner={currentAccount}
        />
      )}
    </div>
  );
};

const StoryElementForm = ReduxForm.reduxForm<StoryElementForm.FormData, StoryElementForm.Props>({
  form: "storyElement",
  validate: memoizee(
    (formData: StoryElementForm.FormData, _props: StoryElementForm.Props) => {
      return {
        paragraph: storyElementParagraphValidator(formData.paragraph),
        caption: storyElementCaptionValidator(formData.caption),
        query: formData.type === "query" && !formData.query ? "A query is required" : undefined,
      };
    },
    { max: 10 },
  ),
  warn: memoizee(
    (formData: StoryElementForm.FormData, props: StoryElementForm.Props) => {
      return {
        query:
          !!formData.query && formData.query.serviceConfig.type !== "speedy" && !formData.query.service
            ? "The service of this query is not accessible"
            : isStricterThan(formData.query?.accessLevel, props.storyAccessLevel)
              ? "This query has a more restrictive access level than the story"
              : isStricterThan(formData.query?.dataset?.accessLevel, props.storyAccessLevel)
                ? "The dataset connected to this query has a more restrictive access level than the story"
                : undefined,
      };
    },
    { max: 10 },
  ),
})(StoryElementFormComponent as any);

export default StoryElementForm;
