import getClassName from "classnames";
import * as React from "react";
import { Constants } from "@triply/utils";
import { LoadingButton } from "#components/index.ts";
import type { Statements, WidgetCollection } from "#reducers/resourceDescriptions.ts";
import InLink from "./InLink.tsx";
import * as styles from "./styles/description.scss";

namespace BackwardDescription {
  export interface Props {
    inLinkWidgets?: WidgetCollection;
    loadBackwardResource: (resource: string, property: string) => {};
    linkPath: string;
    loadPage: (direction: "forward" | "backward", page: number, predicate?: string) => Promise<void>;
    statements: Statements;
    resource: string;
  }

  export interface State {
    loadedPages: number;
    loading: boolean;
  }
}

class BackwardDescription extends React.PureComponent<BackwardDescription.Props, BackwardDescription.State> {
  constructor(props: BackwardDescription.Props) {
    super(props);
    this.state = {
      loadedPages: 1,
      loading: false,
    };
  }
  loadPage = () => {
    const { loadPage } = this.props;
    const { loadedPages } = this.state;
    //if we already have more predicates than expected assume we already fetched the next page
    if (this.getNumberOfPredicates() <= loadedPages * Constants.BACKWARD_PREDICATES_PAGE_SIZE + 1) {
      this.setState({ loading: true });
      loadPage("backward", loadedPages)
        .then(() => {
          this.setState({ loadedPages: loadedPages + 1, loading: false });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loadedPages: loadedPages + 1 });
    }
  };
  renderShowMoreButton = () => {
    return (
      <div className={styles.container}>
        <LoadingButton pulse loading={this.state.loading} onClick={this.loadPage}>
          Show more
        </LoadingButton>
      </div>
    );
  };
  getNumberOfPredicates() {
    const { inLinkWidgets } = this.props;
    return ((inLinkWidgets && inLinkWidgets.properties && inLinkWidgets.properties.children) || []).length;
  }
  render() {
    const { inLinkWidgets, loadBackwardResource, linkPath, loadPage } = this.props;
    const numToRender = this.state.loadedPages * Constants.BACKWARD_PREDICATES_PAGE_SIZE;
    if (!inLinkWidgets) return null;
    const trees = inLinkWidgets.properties?.children || [];
    const thereIsNextPage = this.getNumberOfPredicates() > numToRender;

    return (
      <div className={getClassName(styles.inLinkProperties, "p-5")}>
        {trees.slice(0, numToRender).map((widget) => {
          if (!widget.values) return <></>;
          return (
            <InLink
              key={widget.values[0].getPredicate()}
              label={widget.label}
              trees={widget.values}
              loadResource={loadBackwardResource}
              linkPath={linkPath}
              loadPage={loadPage}
            />
          );
        })}
        {thereIsNextPage && this.renderShowMoreButton()}
      </div>
    );
  }
}
export default BackwardDescription;
