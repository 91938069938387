import { Badge, Divider, IconButton, LinearProgress, Menu, MenuItem, Select, TextField } from "@mui/material";
import getClassName from "classnames";
import { deburr, words } from "lodash-es";
import platform from "platform";
import * as React from "react";
import type { ResizeCallbackData } from "react-resizable";
import { ResizableBox } from "react-resizable";
import { useHistory } from "react-router-dom";
import useResizeObserver from "use-resize-observer";
import { stringifyQuery } from "@core/utils";
import type { Models } from "@triply/utils";
import type { Prefix } from "@triply/utils/prefixUtils";
import { mergePrefixArray } from "@triply/utils/prefixUtils";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import { Button, Dialog, FontAwesomeButton, LinkButton, ServiceTypeBadge } from "#components/index.ts";
import ServiceProtocol from "#components/ServiceProtocol/index.tsx";
import type { TermAutocompleteFunction } from "#components/Sparql/Editor/autocompleters/termAutocomplete.ts";
import SparqlEditor from "#components/Sparql/Editor/index.tsx";
import ConfigurableSparqlVisualization from "#components/Sparql/QueryResults/ConfigurableSparqlVisualziation.tsx";
import type {
  VisualizationConfig,
  VisualizationLabel,
  VisualizationProperties,
} from "#components/Sparql/QueryResults/index.tsx";
import SparqlUpdateResults from "#components/Sparql/UpdateResults/SparqlUpdateResults.tsx";
import { KeyboardShortcutContext } from "#containers/Hotkeys/index.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useCopyToClipboard from "#helpers/hooks/useCopyToClipboard.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { createDatasetPrefix, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { createQuery } from "#reducers/queries.ts";
import ForwardedDragHandle from "../../components/ForwardedDragHandle/index.tsx";
import SaveQueryModal from "./SaveQueryModal.tsx";
import type { TabQuery } from "./useSparqlIDEContext.tsx";
import { ideToQueryConfigLink, useSparqlIDEContext } from "./useSparqlIDEContext.tsx";
import "react-resizable/css/styles.css";
import * as styles from "./styles.scss";

const hiddenVisualizations: VisualizationLabel[] = ["LDFrame"];

const ideVisualizationProperties: VisualizationProperties = {
  Gallery: {
    minimizeColumns: false,
    reduceSpacing: false,
  },
  Table: {
    hideFilters: false,
    hidePagination: false,
  },
};

const SparqlQuery: React.FC<
  {
    tabId: string;
    name: string;
    prefixes: Prefix[];
    searchTerms: TermAutocompleteFunction;
    availableServices: {
      name: string;
      endpoint: string;
      id: string;
      type: Models.SparqlQueryServiceType;
    }[];
  } & TabQuery
> = ({
  query,
  endpointName,
  endpointType,
  error,
  loading,
  visualization,
  visualizationConfig,
  requestDuration,
  requestDelay,
  cache,
  postProcessingDuration,
  prefixes: datasetAndInstancePrefixes,
  queryParsedData,
  queryRawData,
  responseType,
  searchTerms,
  tabId,
  name,
  availableServices,
  responseSize,
  skipResultSizeCheck,
  zeroResultOperationLocations,
  queryType,
  updateResult,
  responseQuery,
}) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const acl = useAcl();
  const dispatch = useDispatch();
  const ds = useCurrentDataset();
  const { ref: resizerRef, height: ideHeight = 600 } = useResizeObserver();
  const [dragging, setDragging] = React.useState(false);
  const [queryValid, setQueryValid] = React.useState(true);
  const [queryPrefixes, setQueryPrefixes] = React.useState<Prefix[]>([]); // Prefixes communicated to the visualization
  const [stagingQueryPrefixes, setStagingQueryPrefixes] = React.useState<Prefix[]>(); // Prefixes currently in the query
  const [editorHeight, setEditorHeight] = React.useState(300);
  const [queryHeight, setQueryHeight] = React.useState(300); // Local state for maintaining the actual code mirror editor height for auto resize
  const OPTIMAL_QUERY_HEIGHT = queryHeight + 20;
  const autoformatContainerRef = React.useRef<HTMLDivElement>(null);

  const { executeQuery, abortQuery, onQueryChange, setVisualizationConfig, setEndpoint, postProcessLargeResult } =
    useSparqlIDEContext();
  const [renderConfig, _setRenderConfig] = React.useState<{ [key: string]: VisualizationConfig }>(() => {
    if (visualization && visualizationConfig) {
      return {
        [visualization]: visualizationConfig,
      };
    } else {
      return {};
    }
  });
  const onRenderConfigUpdate = React.useCallback(
    (visualization: VisualizationLabel, newConfig: VisualizationConfig) => {
      _setRenderConfig((config) => {
        return {
          ...config,
          [visualization]: newConfig,
        };
      });
      setVisualizationConfig(tabId, visualization, newConfig);
    },
    [setVisualizationConfig, tabId],
  );
  const [currentVisualization, _setCurrentVisualization] = React.useState<VisualizationLabel>(
    visualization || "RawTable",
  );
  const setCurrentVisualization = React.useCallback(
    (newValue: VisualizationLabel) => {
      _setCurrentVisualization(newValue);
      setVisualizationConfig(tabId, newValue, renderConfig[newValue]);
    },
    [renderConfig, setVisualizationConfig, tabId],
  );

  const allPrefixes = React.useMemo(
    () => mergePrefixArray(queryPrefixes, datasetAndInstancePrefixes),
    [datasetAndInstancePrefixes, queryPrefixes],
  );

  const configuredService = availableServices.find((item) => item.name === endpointName);

  React.useEffect(() => {
    // The endpoint can no longer be found

    if (!configuredService) {
      if (endpointType) {
        const foundEndpoint = availableServices.find((item) => item.type === endpointType);
        if (foundEndpoint) {
          setEndpoint(tabId, foundEndpoint.name, foundEndpoint.type);
          executeQuery(tabId, { endpoint: foundEndpoint.name });
        }
      } else if (!endpointName && availableServices[0]) {
        // Endpoint was never configured
        const firstService = availableServices[0];
        setEndpoint(tabId, firstService.name, firstService.type);
        executeQuery(tabId, { endpoint: firstService.name });
      }
    } else if (configuredService && !error && !queryRawData && !updateResult) {
      // There is an endpoint, but it hasn't run yet
      executeQuery(tabId);
    }
    // Set serviceType if not set yet (e.g. saved query)
    if (configuredService && !endpointType) {
      setEndpoint(tabId, configuredService.name, configuredService.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSize = React.useCallback((_e: React.SyntheticEvent<Element, Event>, data: ResizeCallbackData) => {
    setEditorHeight(data.size.height);
  }, []);
  const startDrag = React.useCallback(() => {
    setDragging(true);
  }, []);
  const stopDrag = React.useCallback(() => {
    setDragging(false);
  }, []);

  const allowedToCreatePrefixOnData = acl.check({
    action: "editDatasetMetadata",
    context: {
      roleInOwnerAccount: acl.getRoleInAccount(ds?.owner),
      accessLevel: ds?.accessLevel || "private",
      newAccessLevel: undefined,
    },
  }).granted;
  const allowedToUpdate = acl.check({
    action: "importDataToDataset",
    context: {
      roleInOwnerAccount: acl.getRoleInAccount(ds?.owner),
    },
  });
  const createPrefix = React.useCallback(
    (prefix: Models.PrefixUpdate) => {
      if (ds) {
        return dispatch<typeof createDatasetPrefix>(createDatasetPrefix(prefix, ds?.owner, ds))
          .then(() => true)
          .catch((e) => e.message);
      }
      throw new Error("Dataset not found");
    },
    // DS is a new object on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, ds?.id],
  );

  return (
    <div className={getClassName(styles.ideWrapper, { [styles.dragging]: dragging })} ref={resizerRef}>
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          if (loading) {
            abortQuery(tabId);
          } else {
            executeQuery(tabId);
          }
        }}
        className={styles.editor}
      >
        {loading && <LinearProgress className={styles.loadingBar} />}
        <QueryConfig
          tabId={tabId}
          endpointName={endpointName}
          queryValid={queryValid}
          availableServices={availableServices}
          autoformatContainerRef={autoformatContainerRef}
        />
        <Divider />
        <ResizableBox
          height={ideHeight - 248 > editorHeight ? editorHeight : Math.max(ideHeight - 248, 200)}
          axis="y"
          minConstraints={[0, 0]}
          maxConstraints={[Infinity, ideHeight - 248]}
          resizeHandles={["s"]}
          onResizeStart={startDrag}
          onResizeStop={stopDrag}
          onResize={setSize}
          className={styles.dragContainer}
          handle={<ForwardedDragHandle dragging={dragging} />}
        >
          <>
            <SparqlEditor
              initialValue={query} // Updates to this value are ignored in the component
              className={styles.resizableEditor}
              autoFormatButtonContainer={autoformatContainerRef.current}
              onChange={({ query, valid, prefixes, queryType }) => {
                onQueryChange(tabId, query, queryType);
                setQueryValid(valid);
                // stagingQueryPrefixes should only be undefined just after mount
                if (stagingQueryPrefixes === undefined) setQueryPrefixes(prefixes);
                setStagingQueryPrefixes(prefixes);
              }}
              onSubmitQuery={() => {
                if (query === "" || !configuredService) return false;
                if (stagingQueryPrefixes) setQueryPrefixes(stagingQueryPrefixes);
                formRef.current?.requestSubmit();
                return true;
              }}
              prefixes={datasetAndInstancePrefixes}
              searchTerms={searchTerms}
              updateEditorHeight={setQueryHeight}
              noResultRanges={zeroResultOperationLocations}
            />
            {editorHeight !== OPTIMAL_QUERY_HEIGHT && (
              <div className={styles.autoResizeContainer}>
                <div
                  className={getClassName(
                    styles.autoResize,
                    editorHeight > queryHeight ? styles.autoResizeNoBg : styles.autoResizeWhiteBg,
                  )}
                >
                  <FontAwesomeButton
                    icon={editorHeight > OPTIMAL_QUERY_HEIGHT ? "chevron-up" : "chevron-down"}
                    title=""
                    onClick={() => {
                      setEditorHeight(OPTIMAL_QUERY_HEIGHT);
                    }}
                  />
                </div>
              </div>
            )}
          </>
        </ResizableBox>
      </form>
      {queryType === "update" ? (
        <SparqlUpdateResults
          duration={requestDuration ? requestDuration + (postProcessingDuration || 0) : undefined}
          queryDirty={query !== responseQuery}
          delay={requestDelay}
          cache={cache}
          error={error}
          loading={loading || false}
          response={updateResult}
          prefixes={allPrefixes}
          datasetPath={`${ds?.owner.accountName}/${ds?.name}`}
          onCreatePrefix={allowedToCreatePrefixOnData ? createPrefix : undefined}
          onExecuteUpdate={
            allowedToUpdate
              ? () => {
                  executeQuery(tabId, { applyUpdate: true });
                }
              : undefined
          }
        />
      ) : (
        <ConfigurableSparqlVisualization
          duration={requestDuration ? requestDuration + (postProcessingDuration || 0) : undefined}
          delay={requestDelay}
          cache={cache}
          loading={loading || false}
          data={queryParsedData}
          rawData={queryRawData}
          queryName={name}
          hideVisualizations={hiddenVisualizations}
          error={error}
          prefixes={allPrefixes}
          datasetPath={`${ds?.owner.accountName}/${ds?.name}`}
          visualization={currentVisualization}
          visualizationConfig={renderConfig[currentVisualization]}
          setVisualizationConfig={onRenderConfigUpdate}
          onCreatePrefix={allowedToCreatePrefixOnData ? createPrefix : undefined}
          visualizationProperties={ideVisualizationProperties}
          responseSize={responseSize}
          skipResultsCheck={skipResultSizeCheck}
          onPostProcess={() => postProcessLargeResult(tabId)}
          setCurrentVisualization={setCurrentVisualization}
        />
      )}
    </div>
  );
};

export default SparqlQuery;

const QueryConfig: React.FC<{
  tabId: string;
  endpointName: string | undefined;
  queryValid: boolean;
  autoformatContainerRef: React.Ref<HTMLDivElement>;
  availableServices: {
    name: string;
    endpoint: string;
    id: string;
    type: Models.SparqlQueryServiceType;
  }[];
}> = ({ tabId, endpointName, availableServices, queryValid, autoformatContainerRef }) => {
  const { ideConfig, setEndpoint, executeQuery } = useSparqlIDEContext();
  const authenticatedUser = useAuthenticatedUser();
  const ds = useCurrentDataset();
  const acl = useAcl();
  const { push } = useHistory();
  const dispatch = useDispatch();
  const constructUrlToApi = useConstructUrlToApi();

  const { ref: copyRef, copyToClipboard } = useCopyToClipboard();
  // We need a second ref, as the menu blur breaks the access to the one above
  const { ref: menuCopyRef, copyToClipboard: copyToClipboardFromMenu } = useCopyToClipboard();
  const { ref: shortLinkDialogCopyRef, copyToClipboard: copyToClipboardFromShortLinkDialog } = useCopyToClipboard();
  const [shortenedLink, setShortenedLink] = React.useState<string>();

  const shareRef = React.useRef<HTMLButtonElement>(null);

  const [saveQueryModalOpen, setSaveQueryModalOpen] = React.useState(false);
  const [shareMenuOpen, setShareMenuOpen] = React.useState(false);

  const selectedService = availableServices.find((service) => service.name === endpointName);

  const { openShortcutDialog } = React.useContext(KeyboardShortcutContext);

  if (!ideConfig?.queries?.[tabId]) return null;
  const { name, query, visualization, visualizationConfig, queryParsedData, loading, queryType } =
    ideConfig.queries[tabId];
  return (
    <div className="flex center mx-3 my-1">
      <Select
        value={endpointName || ""}
        displayEmpty
        error={!selectedService}
        size="small"
        aria-label="Endpoint"
        variant="filled"
        SelectDisplayProps={{ className: styles.endpointSelector }}
        renderValue={(value) => {
          const service = availableServices?.find((service) => service.name === value);
          if (!service) {
            return <i>Endpoint {endpointName} is no longer available</i>;
          }
          return (
            <div className="flex center">
              <ServiceTypeBadge type={service.type} size="xs" className="mr-3" /> {service.name}
            </div>
          );
        }}
        onChange={(event) => {
          const service = availableServices.find((service) => service.name === event.target.value);
          if (service) {
            setEndpoint(tabId, service.name, service.type);
            executeQuery(tabId, { endpoint: event.target.value });
          } else {
            // Should never happen
            throw new Error("Unknown service");
          }
        }}
      >
        {availableServices.map((service) => (
          <MenuItem key={service.endpoint} title={service.endpoint} value={service.name}>
            <div className="flex column">
              <div className="flex center">
                <div className="flex center mr-2">
                  <ServiceTypeBadge type={service.type} size="sm" className="mr-2" /> {service.name}
                </div>
                <ServiceProtocol serviceType={service.type} />
              </div>
              <span>{service.endpoint}</span>
            </div>
          </MenuItem>
        ))}
      </Select>
      {selectedService && (
        <LinkButton className="ml-3" onClickOrEnter={() => copyToClipboard(selectedService.endpoint)}>
          Copy endpoint URL
        </LinkButton>
      )}
      <div className="grow" ref={copyRef} />
      <div className="flex center">
        <IconButton
          size="medium"
          onClick={openShortcutDialog}
          aria-label="Show keyboard shortcuts"
          title={`Show keyboard shortcuts (${platform.os?.family?.startsWith("OS") ? "⌘" : "Ctrl"}+Shift+/)`}
          aria-keyshortcuts="Control+Shift+/ Command+Shift+/"
        >
          <FontAwesomeIcon icon="keyboard" />
        </IconButton>
        <div className="flex" ref={autoformatContainerRef} />
        {authenticatedUser && (
          <>
            <IconButton
              size="medium"
              onClick={() => setSaveQueryModalOpen(true)}
              aria-label="Save query"
              title="Save query"
            >
              <FontAwesomeIcon icon={["fas", "floppy-disk"]} />
            </IconButton>
            {saveQueryModalOpen && (
              <SaveQueryModal
                authenticatedUser={authenticatedUser}
                close={() => setSaveQueryModalOpen(false)}
                onSubmit={async (accountName) => {
                  const createQueryPayload: Models.QueryCreate = {
                    name: words(deburr(name)).join("-"), // KebabCase will remove the common invalid Tab names
                    description: "",
                    dataset: ds?.id,
                    serviceConfig: { type: selectedService?.type || "speedy" },
                    accessLevel: acl.check({
                      action: "createQuery",
                      context: {
                        roleInOwnerAccount: acl.getRoleInAccount(authenticatedUser),
                        accessLevel: "private",
                      },
                    }).granted
                      ? "private"
                      : "public",
                    requestConfig: {
                      payload: {
                        query: query,
                      },
                    },
                    renderConfig: {
                      output: visualization,
                      settings: visualizationConfig,
                    },
                    generateNewName: true,
                  };
                  return dispatch<typeof createQuery>(createQuery(accountName, createQueryPayload)).then((response) => {
                    // Redirect user to new query
                    push({
                      pathname: `/${accountName}/-/queries/${response.body.name}`,
                    });
                  });
                }}
              />
            )}
          </>
        )}
        <IconButton
          size="medium"
          disabled={!selectedService}
          ref={shareRef}
          onClick={() => setShareMenuOpen(true)}
          aria-label="Open share query menu"
          title="Share"
        >
          <FontAwesomeIcon icon="share-nodes" />
        </IconButton>
        <Menu
          open={shareMenuOpen}
          onClose={() => setShareMenuOpen(false)}
          anchorEl={shareRef.current}
          MenuListProps={{ dense: true }}
        >
          <div ref={menuCopyRef} />
          <MenuItem
            onClick={() => {
              const baseUrl = new URL(window.location.toString());
              baseUrl.hash = stringifyQuery(
                ideToQueryConfigLink(
                  {
                    name,
                    query,
                    endpointName: selectedService?.name,
                    endpointType: selectedService?.type,
                    visualization,
                    visualizationConfig,
                  },
                  selectedService!.endpoint,
                ),
              );
              copyToClipboardFromMenu(baseUrl.toString());
            }}
          >
            Share query as URL
          </MenuItem>
          {authenticatedUser && (
            <MenuItem
              onClick={() => {
                const baseUrl = new URL(window.location.toString());
                baseUrl.hash = stringifyQuery(
                  ideToQueryConfigLink(
                    {
                      name,
                      query,
                      endpointName: selectedService?.name,
                      endpointType: selectedService?.type,
                      visualization,
                      visualizationConfig,
                    },
                    selectedService!.endpoint,
                  ),
                );
                fetch(constructUrlToApi({ pathname: "/short" }), {
                  method: "Post",
                  credentials: "same-origin",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({ longUrl: baseUrl.toString() }),
                })
                  .then(async (response) => {
                    if (response.ok) {
                      const body = await response.json();
                      const didCopy = copyToClipboardFromMenu(body.shortUrl);
                      // In WebKit doing async things will cause the function to lose its right to copy stuff see:
                      // https://stackoverflow.com/questions/70179363/navigator-clipboard-copy-doesnt-work-on-safari-when-the-copy-text-is-grabbed-via
                      // (note: this is not our exact API, but does illustrate the same issue)
                      if (!didCopy) {
                        setShareMenuOpen(false);
                        setShortenedLink(body.shortUrl);
                      }
                    }
                  })
                  .catch((e) => {
                    console.error("Could not retrieve short url", e);
                  });
              }}
            >
              Share query as shortened URL
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              copyToClipboardFromMenu(
                `curl ${selectedService!.endpoint} --data query="${encodeURIComponent(query)}" -X POST`,
              );
            }}
          >
            Share query as cURL
          </MenuItem>
        </Menu>
        <Badge
          className="pl-2"
          color="error"
          overlap="circular"
          title={!selectedService ? "No endpoint selected" : queryValid ? undefined : "Invalid query"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={queryValid ? undefined : <FontAwesomeIcon size="sm" icon="exclamation-triangle" />}
        >
          <Button
            color="primary"
            aria-label={loading ? "Abort query" : "Execute query"}
            aria-keyshortcuts="Control+Enter Command+Enter"
            title={`${loading ? "Abort" : "Execute"} query (${platform.os?.family?.startsWith("OS") ? "⌘" : "Ctrl"}+Enter)`}
            disabled={query === "" || !selectedService}
            className={getClassName(
              styles.executeSparqlButton,
              queryType === "query" ? styles.executeQueryButton : styles.executeUpdateButton,
            )}
            type="submit"
            startIcon={
              <FontAwesomeIcon
                icon={loading ? ["fas", "stop"] : ["fas", "play"]}
                beat={loading}
                className={styles.playIcon}
              />
            }
          >
            {queryType === "query" ? "" : "Preview"}
          </Button>
        </Badge>
      </div>
      <Dialog
        open={!!shortenedLink}
        onClose={() => setShortenedLink(undefined)}
        title="Shortened Link generated"
        maxWidth="sm"
        fullWidth
      >
        <div ref={shortLinkDialogCopyRef} />
        <TextField fullWidth variant="filled" disabled value={shortenedLink} label="Link" className="px-3" />
        <div className="p-3">
          <Button
            color="primary"
            startIcon={<FontAwesomeIcon icon="copy" />}
            onClick={() => {
              copyToClipboardFromShortLinkDialog(shortenedLink!);
              setShortenedLink(undefined);
            }}
          >
            Copy to clipboard
          </Button>
          <Button variant="text" onClick={() => setShortenedLink(undefined)}>
            Close
          </Button>
        </div>
      </Dialog>
    </div>
  );
};
