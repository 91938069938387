import { Alert } from "@mui/material";
import { forEach } from "lodash-es";
import * as React from "react";
import { NTriplyStatementToQuad, quadToNtriply } from "@core/utils/nTriply";
import type { NtriplyStatements, PrefixInfo } from "@triply/utils/Models";
import { getPrefixInfoFromIri } from "@triply/utils/prefixUtils";
import { factories, getWriter, parse } from "@triplydb/data-factory";
import type { Prefixes } from "@triplydb/data-factory/serialize";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { Button, ConfirmationDialog, Dialog, FontAwesomeIcon, LoadingButton } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../../reducers/datasetManagement";
import { getGraphs } from "../../../reducers/graphs";
import QuadEditor from "../QuadEditor/index";
import { printQuadStatements } from "./utils";
import * as styles from "../style.scss";

const factory = factories.compliant;

const EditStatements: React.FC<{
  quadsToEdit: NtriplyStatements;
}> = ({ quadsToEdit }) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const [serializedString, setSerializedString] = React.useState<string | undefined>();
  const [quadsToAdd, setQuadsToAdd] = React.useState<NtriplyStatements>([]);
  const [error, setError] = React.useState<string | undefined>();
  const consoleUrl = useConstructConsoleUrl()();
  const currentDs = useCurrentDataset()!;
  const prefixes = useDatasetPrefixes();
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/sparql`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const onClose = () => {
    if (isDirty) {
      setConfirmationDialogOpen(true);
    } else {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    const quads = quadsToEdit.map((tquad) => NTriplyStatementToQuad(tquad));
    // Checking which prefixes are used in the quads we are editing
    let prefixesUsed: PrefixInfo[] = [];
    forEach(quadsToEdit, (quad) => {
      if (quad[3]) {
        const graphPrefixInfo = getPrefixInfoFromIri(quad[3].value, prefixes);
        if (graphPrefixInfo && graphPrefixInfo.prefixLabel) prefixesUsed.push(graphPrefixInfo);
      }
      const subPrefixInfo = getPrefixInfoFromIri(quad[0].value, prefixes);
      const predPrefixInfo = getPrefixInfoFromIri(quad[1].value, prefixes);
      if (subPrefixInfo && subPrefixInfo.prefixLabel) prefixesUsed.push(subPrefixInfo);
      if (predPrefixInfo && predPrefixInfo.prefixLabel) prefixesUsed.push(predPrefixInfo);
      if (quad[2].termType === "NamedNode") {
        const objPrefixInfo = getPrefixInfoFromIri(quad[2].value, prefixes);
        if (objPrefixInfo && objPrefixInfo.prefixLabel) prefixesUsed.push(objPrefixInfo);
      }
    });
    // Mapping to Prefixes type for writer
    let _prefixes: Prefixes = {};
    forEach(prefixesUsed, (prefix) => {
      if (prefix.prefixLabel) _prefixes[prefix.prefixLabel] = prefix.iri;
    });
    const writer = getWriter({ format: "trig", prefixes: _prefixes });
    forEach(quads, (quad) => writer.addQuad(quad));
    writer.end((error: any, result: string) => {
      if (result) setSerializedString(result);
      if (error) console.error(error);
    });
  }, [quadsToEdit, prefixes]);

  async function submitChanges() {
    setSaving(true);

    const quadsToRemove = quadsToEdit.map((tquad) => NTriplyStatementToQuad(tquad));
    const valuesToRemove = printQuadStatements(quadsToRemove);

    const _quadsToAdd = quadsToAdd.map((tquad) => NTriplyStatementToQuad(tquad));
    const valuesToAdd = printQuadStatements(_quadsToAdd);

    const query = `
    delete {
     ${valuesToRemove}
    }
    insert {
    ${valuesToAdd}
    } where {}
    `;

    const body = new FormData();
    body.set("update", query);

    await fetch(updateUrl, {
      credentials: "same-origin",
      method: "POST",
      body: body,
    });

    await dispatch<typeof refreshDatasetsInfo>(
      refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
    );
    await dispatch<typeof getGraphs>(
      getGraphs({
        accountName: currentDs.owner.accountName,
        datasetName: currentDs.name,
        datasetId: currentDs.id,
      }),
    );

    setSaving(false);
    setOpen(false);
  }

  function getUpdatedAmountText() {
    if (quadsToAdd.length === 0) {
      return `Deleting ${quadsToEdit.length} statement(s)`;
    } else if (quadsToAdd.length > quadsToEdit.length) {
      const addCount = quadsToAdd.length - quadsToEdit.length;
      return `Adding ${addCount}, Editing ${quadsToEdit.length} statement(s)`;
    } else if (quadsToAdd.length < quadsToEdit.length) {
      const deleteCount = quadsToEdit.length - quadsToAdd.length;
      return `Deleting ${deleteCount}, Editing ${quadsToEdit.length - deleteCount} statement(s)`;
    } else {
      return `Editing ${quadsToEdit.length} statement(s)`;
    }
  }

  return (
    <>
      <Button
        color="warning"
        elevation
        onClick={() => setOpen(true)}
        title={`Edit ${quadsToEdit.length} statement(s)`}
        startIcon={<FontAwesomeIcon icon="pencil" />}
        size="small"
        className="m-2 mt-5 "
      >
        {`Edit ${quadsToEdit.length} statement(s)`}
      </Button>
      {confirmationDialogOpen && (
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onConfirm={() => {
            setQuadsToAdd([]);
            setDirty(false);
            setConfirmationDialogOpen(false);
            setOpen(false);
          }}
          onClose={() => setConfirmationDialogOpen(false)}
          title="Are sure you want to close this form?"
          actionLabel="Close"
          description="If you close the form now, all changes will be lost."
        />
      )}

      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="lg"
          fullWidth
          title={`Edit ${quadsToEdit.length} statement(s)`}
          closeButton
          disableEscapeKeyDown
        >
          <div className="px-5 pb-5">
            <div className={styles.quadEditorContainer}>
              <QuadEditor
                className={styles.quadEditor}
                serializedString={serializedString}
                prefixes={prefixes}
                onChange={(values) => {
                  if (values.updatedString !== serializedString) {
                    setDirty(true);
                  } else {
                    setDirty(false);
                  }
                  if (values.updatedString) {
                    try {
                      const n3Statements = parse(values.updatedString, {
                        baseIri: `${consoleUrl}/${currentDs.owner.accountName}/${currentDs.name}`,
                      });
                      const nTriplyStatements = n3Statements.map((n3) => quadToNtriply(n3));
                      setQuadsToAdd(nTriplyStatements);
                      setError(undefined);
                    } catch (e: any) {
                      setError(e.message);
                    }
                  }
                }}
              />
            </div>
            {error && <Alert severity="error">{error}</Alert>}
            <div className="flex">
              <LoadingButton
                onClick={submitChanges}
                className="mt-2"
                type="submit"
                color="secondary"
                elevation
                disabled={!isDirty || error !== undefined}
                loading={saving}
                aria-label="Submit"
              >
                {!isDirty ? "No changes" : error ? "Invalid" : getUpdatedAmountText()}
              </LoadingButton>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default EditStatements;
