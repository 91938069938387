import type { DialogProps as MuiDialogProps } from "@mui/material";
import { Dialog as MuiDialog, DialogTitle as MuiDialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { throttle } from "lodash-es";
import * as React from "react";
import { FontAwesomeIcon } from "#components/index.ts";
import * as styles from "./style.scss";

interface Props extends MuiDialogProps {
  title?: string;
  closeButton?: boolean;
  children: React.ReactNode;
  spacing?: boolean;
}

const Dialog = React.memo<Props>(({ title, children, closeButton, spacing, onClose, ...muiProps }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  // Make sure we disable going back twice. When using the location state the onClose can be called multiple times
  const throttledClose = React.useMemo(
    () => throttle(onClose || (() => {}), 500, { leading: true, trailing: false }),
    [onClose],
  );

  return (
    <MuiDialog fullScreen={fullScreen} {...muiProps} onClose={throttledClose}>
      {closeButton && (
        <IconButton
          aria-label="close"
          className={styles.closeButton}
          onClick={(e) => throttledClose(e, "escapeKeyDown")}
          size="large"
        >
          <FontAwesomeIcon icon="times" size="1x" fixedWidth />
        </IconButton>
      )}
      {title && <MuiDialogTitle className="p-5">{title}</MuiDialogTitle>}
      {children}
    </MuiDialog>
  );
});

export default Dialog;
