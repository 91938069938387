import { Autocomplete, Chip, CircularProgress, debounce, ListItem, ListItemText, TextField } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { stringifyQuery } from "@core/utils";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import { substringMatch } from "#components/Highlight/index.tsx";
import { Highlight } from "#components/index.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { GlobalState } from "#reducers/index.ts";
import useSparql from "./InstanceForm/useSparql";
import type { ClassInfo } from "./ClassInfoContext";
import { ClassInfoContext, getSearchMeta } from "./ClassInfoContext";
import { MAX_SEARCH_RESULTS } from ".";
import * as styles from "./style.scss";

const factory = factories.compliant;

interface SearchResult {
  iri: string;
  label?: string;
  class?: string;
}

const THERE_IS_MORE_KEY = "_";

const getSearchQuery = ({ searchTerm, classInfo }: { searchTerm: string; classInfo: ClassInfo }) => {
  const searchMeta = getSearchMeta({ classInfo: classInfo, hasNodeShape: true });
  const searchTermWords = searchTerm.trim().toLocaleLowerCase().split(" ").filter(Boolean);
  const searchClause = searchTermWords.length
    ? `filter(${searchTermWords.map((searchTermWord) => `contains(lcase(?searchLabel), ${termToString(factory.literal(searchTermWord))})`).join(" && ")})`
    : "";

  return `
  # FindResource

  prefix triply: <https://triplydb.com/Triply/function/>

  select ?resource (triply:firstLabel(?resource) as ?label) (triply:firstLabel(?rdfClass) as ?classLabel) where {
    select distinct ?resource ?rdfClass where {
      ${searchMeta
        .map(
          ({ propertyPaths, rdfClass }) => `{
            bind(${termToString(factory.namedNode(rdfClass))} as ?rdfClass)
            ?resource a ?rdfClass .
            ${searchClause ? `?resource ${propertyPaths.map((p) => p.map((p) => termToString(factory.namedNode(p))).join("/")).join("|")} ?searchLabel .` : ""}
            ${searchClause}
          }`,
        )
        .join("\n union \n")}
    }
    limit ${MAX_SEARCH_RESULTS + 1}
  }
  `;
};

const FindResource: React.FC<{}> = ({}) => {
  const sparql = useSparql();
  const { push } = useHistory();
  const [searchString, setSearchString] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<readonly SearchResult[]>([]);
  const [optionsFor, setOptionsFor] = React.useState("");
  const applyPrefixes = useApplyPrefixes();
  const resource = useCurrentResource();
  const search = useCurrentSearch();
  const classInfo = React.useContext(ClassInfoContext);

  const dsId = useCurrentDataset()?.id;
  const resourceLabel = useSelector((state: GlobalState) =>
    dsId && resource ? state.resourceEditorDescriptions[dsId]?.resources[resource]?.valueLabel : undefined,
  );

  const debouncedQuery = React.useMemo(
    () =>
      debounce(
        (
          { searchTerm, abortSignal }: { searchTerm: string; abortSignal: AbortSignal },
          callback: (results?: readonly SearchResult[]) => void,
        ) => {
          if (!classInfo) return;
          setLoading(true);
          sparql(getSearchQuery({ searchTerm: searchTerm, classInfo: classInfo }), abortSignal)
            .then((results) => {
              callback(
                results.results.bindings.map((binding) => {
                  return {
                    iri: binding["resource"]!.value,
                    label: binding["label"]?.value,
                    class: binding["classLabel"]?.value,
                  };
                }),
              );
            })
            .catch(() => {});
        },
        400,
      ),
    [sparql, classInfo],
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    let active = true;
    debouncedQuery({ searchTerm: searchString, abortSignal: abortController.signal }, (results) => {
      if (active) {
        setLoading(false);
        setOptions(results || []);
        setOptionsFor(searchString);
      }
    });

    return () => {
      active = false;
      abortController.abort("Not needed anymore");
    };
  }, [debouncedQuery, searchString]);

  return (
    <Autocomplete<SearchResult>
      disabled={!classInfo}
      size="small"
      className={getClassName(styles.instanceSearch)}
      componentsProps={{
        popper: {
          style: { width: "fit-content" },
        },
      }}
      renderInput={(props) => {
        return (
          <TextField
            {...(props as any)}
            label="Find"
            fullWidth
            variant="outlined"
            placeholder={resourceLabel || applyPrefixes(resource)}
            InputLabelProps={{ ...props.InputLabelProps, shrink: true }}
            InputProps={{
              ...props.InputProps,
              endAdornment: loading ? (
                <>
                  <CircularProgress color="inherit" size={20} className={styles.loading} />
                  {props.InputProps.endAdornment}
                </>
              ) : (
                props.InputProps.endAdornment
              ),
            }}
          />
        );
      }}
      filterOptions={(x) => x}
      options={
        options.length > MAX_SEARCH_RESULTS
          ? [...options.slice(0, MAX_SEARCH_RESULTS), { iri: THERE_IS_MORE_KEY }]
          : options
      }
      getOptionDisabled={(option) => option.iri === THERE_IS_MORE_KEY}
      onInputChange={(_event, newInputValue) => {
        setSearchString(newInputValue.replace(/"/g, ""));
      }}
      onChange={(_event, newValue) => {
        if (!newValue) return;
        push({
          search: stringifyQuery({ ...search, resource: newValue.iri }),
        });
      }}
      blurOnSelect
      isOptionEqualToValue={(a, b) => a.iri === b.iri}
      getOptionLabel={(option) => option.label || option.iri}
      getOptionKey={(option) => option.iri}
      noOptionsText={searchString === "" ? "No instances found according to the data model" : "No instances found"}
      renderOption={(props, option) => {
        if (option.iri === THERE_IS_MORE_KEY) {
          return (
            <em key={THERE_IS_MORE_KEY} className="m-4">
              <small>{`Showing the first ${MAX_SEARCH_RESULTS} results`}</small>
            </em>
          );
        }
        const label = option.label || applyPrefixes(option.iri);
        return (
          <ListItem {...props}>
            <ListItemText
              primary={
                <span>
                  <Highlight fullText={label} highlightedText={optionsFor} matcher={substringMatch} />
                  {option.class && <Chip label={option.class} size="small" className="ml-2" />}
                </span>
              }
            />
          </ListItem>
        );
      }}
    />
  );
};

export default FindResource;
