import * as React from "react";
import * as yaml from "yaml";
import { FontAwesomeIcon } from "../../components";
import * as styles from "./LNCSMetadata.scss";

type Metadata = {
  authors: {
    orcid: string;
    name: string;
    affiliation: string;
  }[];
  affiliations: {
    id: string;
    name: string;
  }[];
  abstract: string;
  keywords: string[];
};

const LNCSMetadata: React.FC<{ metadataString: string }> = ({ metadataString }) => {
  let parsedMetadata: Metadata | undefined;
  try {
    parsedMetadata = yaml.parse(metadataString);
  } catch (e) {
    console.error("Failed to parse yaml LNCS metadata", e);
  }
  if (
    parsedMetadata &&
    parsedMetadata.authors &&
    parsedMetadata.authors.length > 0 &&
    parsedMetadata.affiliations &&
    parsedMetadata.affiliations.length > 0 &&
    parsedMetadata.keywords &&
    parsedMetadata.keywords.length > 0 &&
    parsedMetadata.abstract
  ) {
    return (
      <div className={styles.paragraph}>
        <div id="authors" className={styles.authors}>
          {parsedMetadata.authors.map((author, i) => (
            <span
              key={JSON.stringify(author) + i}
              property="author"
              className={styles.author}
              resource={author.orcid}
              typeof="Person"
            >
              {author.name}
              <sup>
                {author.affiliation}
                {author.orcid && (
                  <a href={`https://orcid.org/${author.orcid}`} target="_blank" className={styles.orcid}>
                    <FontAwesomeIcon icon={["fab", "orcid"]} />
                    {author.orcid}
                  </a>
                )}
              </sup>
            </span>
          ))}
        </div>
        <div id="affiliations" className={styles.affiliations}>
          {parsedMetadata.affiliations.map((affiliation, i) => (
            <div key={JSON.stringify(affiliation) + i} id={affiliation.id}>
              <sup>{affiliation.id}</sup>
              {affiliation.name}
            </div>
          ))}
        </div>
        <div className={styles.abstract}>
          <h2>Abstract. </h2>
          {parsedMetadata.abstract}
        </div>
        <div id="keywords" className={styles.keywords}>
          <h2>Keywords: </h2>
          {parsedMetadata.keywords.join(" \u00B7 ")}
        </div>
      </div>
    );
  }
  return <div className={styles.paragraph}>{metadataString}</div>;
};

export default LNCSMetadata;
