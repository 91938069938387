import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import { uniqBy } from "lodash-es";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { asyncConnect } from "redux-connect";
import type { UrlInfo } from "@core/utils";
import type { DescribePaginationOptions, Prefixes } from "@triply/utils/Models";
import { mergePrefixArray } from "@triply/utils/prefixUtils";
import { Alert, BrowserBreadCrumbs, FlexContainer } from "#components/index.ts";
import { AclContext } from "#context.ts";
import type { Acl } from "#helpers/Acl.ts";
import { parseSearchString, stringifyQuery } from "#helpers/utils.ts";
import type { Account } from "#reducers/accountCollection.ts";
import { getCurrentAccount } from "#reducers/app.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";
import { getCurrentDataset } from "#reducers/datasetManagement.ts";
import { setLastBrowserResource } from "#reducers/datasets.ts";
import type { Dispatched, DispatchedFn, GlobalState } from "#reducers/index.ts";
import { showNotification } from "#reducers/notifications.ts";
import type { ResourceDescriptions } from "#reducers/resourceDescriptions.ts";
import {
  catchAll,
  descriptionIsLoadedFor,
  descriptionIsOutdatedFor,
  getDescription,
  getDescriptionFor,
  getStatementsAsTree,
  getWidgetCollectionMemoized,
} from "#reducers/resourceDescriptions.ts";
import { getTerms } from "#reducers/triples.ts";
import { getConstructUrlToApi } from "#staticConfig.ts";
import CreateResource from "./CreateResource.tsx";
import Description from "./Description.tsx";
import EditResource from "./EditResource.tsx";
import Meta from "./Meta.tsx";
import RemoveResource from "./RemoveResource.tsx";
import ResourceAutocomplete from "./ResourceAutocomplete.tsx";
import * as styles from "./styles/index.scss";

export namespace Browser {
  export type History = {
    resource: string;
    animationDirection: Browser.State["animationDirection"];
    property?: string;
    key: string;
  }[];
  export interface OwnProps {
    location: {
      search: string;
      state: {
        animationDirection?: Browser.State["animationDirection"];
        history?: Browser.History;
      };
      key: string;
      pathname: string;
    };
    history: any;
  }
  export interface DispatchProps {
    pushState: typeof connectedReactRouter.push;
    go: typeof connectedReactRouter.go;
    replaceState: typeof connectedReactRouter.replace;
    getDescription: DispatchedFn<typeof getDescription>;
    setLastBrowserResource: DispatchedFn<typeof setLastBrowserResource>;
    showNotification: DispatchedFn<typeof showNotification>;
  }
  export interface PropsFromState {
    currentDs?: Dataset;
    currentAccount?: Account;
    datasetResourceDescriptions?: ResourceDescriptions;
    descriptionIsOutdated: boolean;
    consoleUrlInfo?: UrlInfo;
    apiUrlInfo?: UrlInfo;
    globalPrefixes?: Prefixes;
    datasetPrefixes?: Prefixes;
    editMode?: boolean;
  }
  export type Props = OwnProps & DispatchProps & PropsFromState;
  export interface Query {
    resource: string;
    focus: "forward" | "backward";
  }
  export interface State {
    animationDirection?: "forward" | "backward";
  }
}

const getResource = (location: Browser.OwnProps["location"]) => {
  const parsed = parseSearchString(location.search).resource;
  if (typeof parsed === "string" && parsed.length) return parsed;
  return undefined;
};

@asyncConnect<GlobalState>([
  {
    promise: async ({ location, store: { dispatch, getState }, helpers: { redirect } }) => {
      try {
        const state: GlobalState = getState();
        const query = parseSearchString(location.search);
        const currentDs = getCurrentDataset(state);
        if (!state.config.staticConfig) return;

        if (currentDs) {
          let resource: string | undefined;
          if (typeof query.resource === "string" && query.resource.length) {
            /**
             * We've got request a resource via url arguments
             */
            resource = query.resource;
          } else if (currentDs.exampleResources.length) {
            /**
             * Choose a resource from example resources
             */
            resource = currentDs.exampleResources[0];
            return redirect(
              `/${currentDs.owner.accountName}/${currentDs.name}/browser?${stringifyQuery({ resource: resource })}`,
            );
          } else if (currentDs.graphCount) {
            /**
             * Choose a random resource
             */

            const terms = await (dispatch<any>(
              getTerms(currentDs, { pos: "subject", termType: "NamedNode" }),
            ) as Dispatched<typeof getTerms>);

            if (terms.body[0]) {
              return redirect(
                `/${currentDs.owner.accountName}/${currentDs.name}/browser?${stringifyQuery({
                  resource: terms.body[0],
                })}`,
              );
            }
          }
          if (resource && state.datasets[currentDs.id].lastBrowserResource !== resource) {
            /**
             * Mark this resource as the last browser resource visited
             */
            dispatch(setLastBrowserResource(currentDs.id, resource));
          }
          if (
            resource &&
            !descriptionIsLoadedFor({
              scope: "all",
              state: state.resourceDescriptions,
              dataset: currentDs,
              resource: resource,
              concise: false,
            })
          ) {
            /**
             * Fetch resource description when needed
             */
            return dispatch<any>(getDescription({ dataset: currentDs, resource: resource, concise: false }));
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
])
class Browser extends React.PureComponent<Browser.Props, Browser.State> {
  static contextType = AclContext;
  context!: Acl;
  descriptionRef: React.RefObject<HTMLDivElement | null>;
  constructor(props: Browser.Props, ctx: Acl) {
    super(props);
    this.state = {
      animationDirection: undefined,
    };
    this.descriptionRef = React.createRef();

    const { location, currentDs, datasetResourceDescriptions } = props;
    //fetch descriptions for resources in the history (descriptions are lost on refresh, but history is not)
    //needed for bread crumbs
    if (location.state && location.state.history) {
      uniqBy(location.state.history, "resource").forEach((h: any) => {
        if (
          currentDs &&
          !descriptionIsLoadedFor({
            scope: "dataset",
            state: datasetResourceDescriptions,
            dataset: currentDs,
            resource: h.resource,
            concise: false,
          })
        ) {
          props.getDescription({ dataset: currentDs, resource: h.resource, concise: false }).catch(() => {});
        }
      });
    }
  }

  private getFocus(): Browser.Query["focus"] {
    const query = parseSearchString(this.props.location.search);
    return query.focus === "backward" ? "backward" : "forward";
  }

  UNSAFE_componentWillReceiveProps(nextProps: Browser.Props) {
    const nextResource = getResource(nextProps.location);
    if (nextResource && nextResource !== getResource(this.props.location) && nextProps.currentDs) {
      this.props.setLastBrowserResource(nextProps.currentDs.id, nextResource);
    }

    if (this.props.location !== nextProps.location) {
      this.setState({ animationDirection: this.getAnimationDirection(nextProps) });
    }

    if (nextProps.currentDs && nextResource && !this.props.descriptionIsOutdated && nextProps.descriptionIsOutdated) {
      nextProps
        .getDescription({ dataset: nextProps.currentDs, resource: nextResource, concise: false })
        // .then(() => nextProps.showNotification("The data was reloaded after a change in the dataset.", "info"))
        .catch(() => {});
    }
  }

  private getAnimationDirection(nextProps: Browser.Props) {
    const { location } = this.props;

    if (nextProps.history.action === "POP") {
      //we are getting a page from the history
      if (
        location.state &&
        location.state.history &&
        nextProps.location.key === location.state.history[location.state.history.length - 1].key
      ) {
        //we are going one step back in history
        //invert the animation direction from the current location state
        return this.invertAnimationDirection((location.state && location.state.animationDirection) || undefined);
      }

      if (
        nextProps.location.state &&
        nextProps.location.state.history &&
        nextProps.location.state.history[nextProps.location.state.history.length - 1].key === location.key
      ) {
        //we are going one step forward in history
        //use the animation direction from the location state
        return (nextProps.location.state && nextProps.location.state.animationDirection) || undefined;
      }

      //we are going multiple steps back or forward in history
      //don't do any animation
      return undefined;
    } else {
      //use animationDirection from the location state
      return (nextProps.location.state && nextProps.location.state.animationDirection) || undefined;
    }
  }

  private invertAnimationDirection(animationDirection: Browser.State["animationDirection"]) {
    if (animationDirection === "forward") return "backward";
    if (animationDirection === "backward") return "forward";
    return undefined;
  }

  private loadBackwardResource = (resource: string, propertyLabel: string) =>
    this.loadResource(resource, "backward", propertyLabel);

  private loadForwardResource = (resource: string, propertyLabel: string) =>
    this.loadResource(resource, "forward", propertyLabel);

  private loadResource = (
    resource: string,
    animationDirection: Browser.State["animationDirection"],
    propertyLabel: string,
  ) => {
    const { pushState, location } = this.props;
    pushState({
      ...(location as any),
      search: stringifyQuery({ resource: resource }),
      state: {
        animationDirection: animationDirection,
        history: [
          ...((location.state && location.state.history) || []),
          {
            resource: getResource(location),
            animationDirection: animationDirection,
            property: propertyLabel,
            key: location.key,
          },
        ],
      },
    });
    return {};
  };

  private changeFocus = (focus: Browser.Query["focus"]) => {
    if (this.getFocus() === focus) return;
    const { replaceState, location } = this.props;
    replaceState({
      state: location.state,
      search: stringifyQuery({ resource: getResource(location), focus: focus }),
    });
  };

  private goToResource = (resource: string) => {
    const { pushState, location, currentAccount, currentDs } = this.props;
    if (currentAccount && currentDs) {
      pushState({
        pathname: `/${currentAccount.accountName}/${currentDs.name}/browser`,
        search: stringifyQuery({ resource }),
        key: location.key,
      });
    }
  };

  loadPage = async (direction: Browser.Query["focus"], page: number, predicate?: string) => {
    const { getDescription, currentDs, location } = this.props;
    if (!currentDs) return;
    const resource = getResource(location);
    if (!resource) return;
    const paginationOptions: DescribePaginationOptions = {
      direction: direction,
      page: page,
    };
    if (predicate) paginationOptions.predicate = predicate;
    await getDescription({
      dataset: currentDs,
      resource: resource,
      concise: false,
      paginationOptions: paginationOptions,
    });
  };

  private catchAllWidgets = [catchAll];

  render() {
    const {
      apiUrlInfo,
      consoleUrlInfo,
      currentAccount,
      currentDs,
      datasetPrefixes,
      globalPrefixes,
      go,
      location,
      datasetResourceDescriptions,
      editMode,
    } = this.props;
    if (!currentDs || !currentAccount || !consoleUrlInfo || !apiUrlInfo) return null;
    const userAllowedToCreateData = this.context.check({
      action: "importDataToDataset",
      context: { roleInOwnerAccount: this.context.getRoleInAccount(currentAccount) },
    }).granted;

    const editModeEnabled = userAllowedToCreateData && editMode;
    if (currentDs.graphCount === 0) {
      if (userAllowedToCreateData) {
        return (
          <FlexContainer innerClassName={getClassName(styles.container, "my-5")}>
            <div>
              <Link to={`/${currentAccount.accountName}/${currentDs.name}/graphs`} className="noLinkDecoration">
                <Alert className="shadow" key="alert" message="This dataset is empty, start by importing data" info />
              </Link>
            </div>
          </FlexContainer>
        );
      } else {
        return <div className={styles.noContentMsg}>This dataset is empty</div>;
      }
    }
    const resource = getResource(location);
    if (!resource) return null;
    const { animationDirection } = this.state;

    const resourceDescriptionStatements = getDescriptionFor({
      scope: "dataset",
      state: datasetResourceDescriptions,
      dataset: currentDs,
      resource: resource,
      concise: false,
    })?.statements;

    //Avoid cache-busting the memoized `getWidgetCollection` and `getStatementsAsTree` functions
    //This might happen when you pass e.g. `[catchAll]` as argument, as that will create a new array each time
    const outLinkWidgets =
      resourceDescriptionStatements &&
      getWidgetCollectionMemoized(getStatementsAsTree(resource, resourceDescriptionStatements, "forward"));
    const constructUrlToApi = getConstructUrlToApi({ apiUrlInfo, consoleUrlInfo });
    const inLinkWidgets =
      resourceDescriptionStatements &&
      getWidgetCollectionMemoized(
        getStatementsAsTree(resource, resourceDescriptionStatements, "backward"),
        this.catchAllWidgets,
      );

    return (
      <FlexContainer innerClassName={getClassName(styles.container, "my-5")}>
        <Meta resourceId={resource} currentAccount={currentAccount} currentDs={currentDs} widgets={outLinkWidgets} />
        {editModeEnabled && (
          <div className="flex wrap center g-3 mb-5">
            <CreateResource />
            <div className="grow" />
            <EditResource resource={resource} />
            <RemoveResource />
          </div>
        )}
        {currentDs.graphCount > 0 && (
          <ResourceAutocomplete
            autocompleteUrl={constructUrlToApi({
              pathname: `/datasets/${currentAccount.accountName}/${currentDs.name}/terms`,
            })}
            selectSearchTerm={this.goToResource}
            prefixes={mergePrefixArray(datasetPrefixes || [], globalPrefixes || [])}
            resource={resource}
          />
        )}
        {resourceDescriptionStatements && resourceDescriptionStatements.length > 0 && currentDs.graphCount > 0 && (
          <>
            {!editModeEnabled && (
              <BrowserBreadCrumbs
                history={location.state && location.state.history}
                datasetResourceDescriptions={datasetResourceDescriptions || {}}
                currentResource={resource}
                linkPath={`/${currentAccount.accountName}/${currentDs.name}/browser`}
                go={go}
              />
            )}
            <TransitionGroup className={getClassName(styles.transitionGroup, animationDirection)}>
              <CSSTransition
                key={resource}
                classNames="resource"
                timeout={500}
                component="div"
                nodeRef={this.descriptionRef}
              >
                <Description
                  key={resource}
                  resource={resource}
                  statements={resourceDescriptionStatements}
                  focus={this.getFocus()}
                  loadBackwardResource={this.loadBackwardResource}
                  loadForwardResource={this.loadForwardResource}
                  changeFocus={this.changeFocus}
                  outLinkWidgets={outLinkWidgets}
                  inLinkWidgets={inLinkWidgets}
                  linkPath={`/${currentAccount.accountName}/${currentDs.name}/browser`}
                  loadPage={this.loadPage}
                  ref={this.descriptionRef}
                />
              </CSSTransition>
            </TransitionGroup>
          </>
        )}
        {currentDs.graphCount > 0 && resourceDescriptionStatements && resourceDescriptionStatements.length === 0 && (
          <Alert message={`Resource ${resource} does not appear in this dataset`} warning className="mb-4 shadow" />
        )}
      </FlexContainer>
    );
  }
}

export default connect<
  Browser.PropsFromState,
  { [K in keyof Browser.DispatchProps]: any },
  Browser.OwnProps,
  GlobalState
>(
  (state, props) => {
    const currentDs = getCurrentDataset(state);
    const resource = getResource(props.location);
    return {
      currentDs: currentDs,
      currentAccount: getCurrentAccount(state),
      datasetResourceDescriptions: currentDs && state.resourceDescriptions[currentDs.id],
      descriptionIsOutdated:
        !!currentDs &&
        !!resource &&
        descriptionIsOutdatedFor({
          scope: "all",
          state: state.resourceDescriptions,
          dataset: currentDs,
          resource: resource,
          concise: false,
        }),
      consoleUrlInfo: state.config.staticConfig?.consoleUrl,
      apiUrlInfo: state.config.staticConfig?.apiUrl,
      globalPrefixes: state.config.clientConfig?.prefixes,
      datasetPrefixes: currentDs?.prefixes,
      editMode: !!(state.config.staticConfig?.editMode && currentDs?.statements && currentDs.statements <= 10_000),
    };
  },
  //dispatch
  {
    pushState: connectedReactRouter.push,
    go: connectedReactRouter.go,
    replaceState: connectedReactRouter.replace,
    getDescription: getDescription,
    setLastBrowserResource: setLastBrowserResource,
    showNotification: showNotification,
  },
)(Browser as any);
