import { FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from "@mui/material";
import getClassName from "classnames";
import { upperFirst } from "lodash-es";
import * as React from "react";
import { getPrefixed, mergePrefixArray } from "@triply/utils/prefixUtils";
import { extractQueryPrefixes } from "#components/Sparql/SparqlUtils.ts";
import type { QueryVariableFieldProps } from "./QueryVariableField.tsx";
import * as styles from "./style.scss";

const VariableSelectField: React.FC<QueryVariableFieldProps> = ({
  testValue,
  onTestValueChange,
  variableDefinition,
  required,
  fieldVariant,
  prefixes,
  getQueryString,
  error,
}) => {
  const allPrefixes = mergePrefixArray(extractQueryPrefixes(getQueryString()), prefixes);
  const defaultValueLabel = variableDefinition.defaultValue
    ? `${variableDefinition.termType === "NamedNode" ? getPrefixed(variableDefinition.defaultValue, allPrefixes) || variableDefinition.defaultValue : variableDefinition.defaultValue} (default)`
    : "(None)";

  if (!variableDefinition.allowedValues) return null;
  return (
    <FormControl
      variant={fieldVariant || "outlined"}
      className={styles.muiOverride}
      required={required}
      size="small"
      error={!!error}
    >
      <InputLabel shrink>{upperFirst(variableDefinition.name)}</InputLabel>
      <Select
        size="small"
        className={getClassName({ [styles.placeholder]: !testValue })}
        label={variableDefinition.name}
        value={testValue || ""}
        displayEmpty
        onChange={(event) => onTestValueChange(event.target.value as string)}
        variant={fieldVariant || "outlined"}
        renderValue={(term) => (term === "" ? defaultValueLabel : getPrefixed(term, allPrefixes) || term)}
      >
        <MenuItem value="" dense>
          {defaultValueLabel}
        </MenuItem>
        {variableDefinition.allowedValues.map((value) => {
          const primary =
            variableDefinition.termType === "NamedNode" ? getPrefixed(value, allPrefixes) || value : value;
          const secondary = variableDefinition.termType === "NamedNode" && primary !== value ? value : undefined;
          return (
            <MenuItem key={value} value={value} dense>
              <ListItemText primary={primary} secondary={secondary} />
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
export default VariableSelectField;
