import bytes from "bytes";
import getClassName from "classnames";
import * as React from "react";
import { formatNumber } from "@core/utils/formatting";
import * as styles from "./LightUserLimitWidget.scss";

const widgetWidth = 300;
const widgetHeight = widgetWidth / 2;
const strokeWidth = 15; // in px
const fillPercentile = 0.6;
const radius = (widgetWidth / 4) * fillPercentile + strokeWidth;

const formatBytes = (num: number) => bytes(num, { unitSeparator: " " });

interface Props {
  limit: number;
  value: number;
  remainingDisplay?: "percentage" | "counter";
  formatter?: "bytes" | "number";
  suffix?: string;
}
function getFontSizeForString(str: string) {
  if (str && str.length) {
    if (str.length < 5) return "3em";
    if (str.length < 10) {
      return "2em";
    }
  }
  return "1em";
}

const LimitWidget: React.FC<Props> = ({
  value,
  limit,
  remainingDisplay = "counter",
  formatter = "number",
  suffix = "",
}) => {
  const percentage = value / limit >= 1 ? 1 : value / limit;
  const circleRef = React.useRef<SVGCircleElement>(null);
  React.useEffect(() => {
    if (circleRef.current) {
      // Math could be simplified, however let's show everything for readability
      circleRef.current.style.strokeDasharray = (2 * radius * Math.PI) / 2 + "%"; // 50% of the circle
      circleRef.current.style.strokeDashoffset = ((Math.PI * (percentage * radius * 2)) / 2) * -1 + "%";
    }
  }, [circleRef, percentage]);
  const selectedFormatter = formatter === "number" ? formatNumber : formatBytes;
  const hintText = `${selectedFormatter(value)}/${selectedFormatter(limit)} ${suffix}`;
  const text =
    remainingDisplay === "counter"
      ? `${selectedFormatter(value)}/${selectedFormatter(limit)}`
      : `${formatNumber(Math.round((value / limit) * 100))}%`;
  return (
    <div className={styles.limitWidget} title={hintText}>
      <svg className={styles.circle} viewBox={[0, 0, widgetWidth, widgetHeight].join(" ")}>
        <circle className={styles.lowerCircle} r={fillPercentile * 100 + "%"} cx="50%" cy="100%" />
        <circle
          ref={circleRef}
          className={getClassName(styles.upperCircle, { [styles.warning]: percentage > 0.9 })}
          r={fillPercentile * 100 + "%"}
          cx="50%"
          cy="100%"
        />
      </svg>
      <div className={styles.limitBox}>
        <span style={{ fontSize: getFontSizeForString(text) }}>{text}</span>
      </div>
    </div>
  );
};

export default LimitWidget;
