import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { HintWrapper } from "#components/index.ts";
import type { User } from "#reducers/accountCollection.ts";
import type { GlobalState } from "#reducers/index.ts";
import LimitWidget from "./LightUserLimitWidget.tsx";
import * as styles from "./LightUserUsageOverview.scss";

export interface Props {
  currentAccount: User;
}

const UsageOverview: React.FC<Props> = ({ currentAccount }) => {
  const userLimits = useSelector((state: GlobalState) => {
    return state.config.clientConfig?.triplydb;
  });
  if (!userLimits) return null;
  return (
    <div className="whiteSink">
      <h3>Usage</h3>
      <div className={getClassName("flex", "wrap", styles.limitContainer)}>
        {userLimits.userStatementLimit && (
          <Card variant="outlined" className={styles.card}>
            <CardHeader
              title={
                <HintWrapper
                  className={styles.cardHeaderWithHint}
                  hint="Statements imported from other datasets are excluded"
                >
                  <Typography variant="h5">Statements</Typography>
                </HintWrapper>
              }
            />
            <CardContent>
              <LimitWidget
                value={currentAccount.usageInfo?.statementCount || 0}
                limit={userLimits.userStatementLimit}
                remainingDisplay="percentage"
                suffix="statements"
              />
            </CardContent>
          </Card>
        )}
        {userLimits.userGraphLimit && (
          <Card variant="outlined" className={styles.card}>
            <CardHeader title="Graphs" className={styles.cardHeader} />
            <CardContent>
              <LimitWidget
                limit={userLimits.userGraphLimit}
                value={currentAccount.usageInfo?.graphCount || 0}
                suffix="graphs"
              />
            </CardContent>
          </Card>
        )}
        {userLimits.userAssetByteLimit && (
          <Card variant="outlined" className={styles.card}>
            <CardHeader title="Assets" className={styles.cardHeader} />
            <CardContent>
              <LimitWidget
                limit={userLimits.userAssetByteLimit}
                value={currentAccount.usageInfo?.assetBytes || 0}
                remainingDisplay="percentage"
                formatter="bytes"
              />
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};
export default UsageOverview;
