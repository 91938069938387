import * as React from "react";
import { useSelector } from "react-redux";
import type { MarkRequired } from "ts-essentials";
import { formatNumber } from "@core/utils/formatting";
import type { FileRowProps } from "#components/FileRow/index.tsx";
import { Button, FileRow, FontAwesomeIcon, LoadingButton } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import {
  cancelAllUploadsOfKindInDs,
  getEnqueuedUploadSize,
  getNumEnqueuedUploads,
  getOngoingUploadSize,
} from "#helpers/tusUploadManagement.ts";
import { ensureTrailingDot } from "#helpers/utils.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import type { Job, JobFileInfo } from "#reducers/datasets.ts";
import {
  deleteAllJobsInErrorState,
  deleteJob,
  removeFileFromJob,
  resetJob,
  startJob,
  updateJobProgress,
  updateStartWhenUploaded,
} from "#reducers/datasets.ts";
import type { GlobalState } from "#reducers/index.ts";
import type { JobUpload as JobUploadInterface } from "#reducers/uploading.ts";
import { cancelUpload, computePartialProgress, getUploadingFiles } from "#reducers/uploading.ts";
import * as styles from "./style.scss";

namespace JobUpload {
  export interface Props {
    job?: Job;
  }
}

function cannotRemoveFiles(job: Job) {
  // While the job is in these states, the user should not be able to remove files.
  return job.status === "indexing" || job.status === "finished" || job.status === "error" || job.status === "pending";
}

const getFileRowProps = (
  job: Job,
  file: MarkRequired<Partial<JobFileInfo>, "fileName" | "fileSize">,
  removeFile: (job: Job, fileName: string, sourceFileId: string | undefined, uploadInfo?: JobUploadInterface) => void,
  uploadProps?: JobUploadInterface,
): FileRowProps & { key: string } => {
  return {
    key: file.sourceFileId || uploadProps?.uploadId || "",
    isUploading: !!uploadProps,
    uploadProgress: uploadProps ? uploadProps.progress : undefined,
    error: uploadProps?.error ? ensureTrailingDot(uploadProps.error) : undefined,
    fileName: file.fileName,
    fileSize: file.fileSize,
    // Passing undefined here will remove the ability to remove files from the upload list.
    removeFile: cannotRemoveFiles(job)
      ? undefined
      : () => {
          removeFile(job, file.fileName, file.sourceFileId, uploadProps);
        },
    removeFileToolTip: uploadProps ? "Cancel upload" : "Remove file",
  };
};

const JobUpload: React.FC<JobUpload.Props> = ({ job }) => {
  const dispatch = useDispatch();

  const currentDs = useCurrentDataset();
  const dataState = useSelector((state: GlobalState) => currentDs?.id && state.datasets[currentDs?.id]) || undefined;
  const starting = !!dataState?.startingJob;
  const startingWhenUploaded = !!dataState?.startingJobWhenUploaded;
  const uploading = useSelector((state: GlobalState) =>
    currentDs ? getUploadingFiles(state, currentDs.id) : undefined,
  );
  const removeFile = (
    job: Job,
    fileName: string,
    sourceFileId: string | undefined,
    uploadInfo?: JobUploadInterface,
  ) => {
    if (uploadInfo) {
      dispatch<typeof cancelUpload>(cancelUpload(uploadInfo, job));
      dispatch<typeof updateJobProgress>(
        updateJobProgress(
          job.datasetId,
          uploading ? computePartialProgress(uploading) : undefined,
          undefined,
          getEnqueuedUploadSize("jobUpload", job.datasetId),
          getOngoingUploadSize("jobUpload", job.datasetId),
        ),
      );
    } else {
      if (!sourceFileId) {
        throw new Error("Cannot remove file without a file id");
      }
      dispatch<typeof removeFileFromJob>(removeFileFromJob(fileName, job, sourceFileId)).catch(() => {});
    }
  };

  // Previously, we would return if job wasn't defined. Now we don't return, and display the
  // heading instead.
  if (!job) {
    return (
      <div className={styles.btns}>
        <h4 className={styles.heading}>Uploaded files</h4>
        <hr className={styles.line} />
      </div>
    );
  }

  const hasUploadedFiles = !!job.files?.length;
  const isUploading = !!uploading?.length;
  const hasFiles = hasUploadedFiles || isUploading;
  const hasOngoingUpload = uploading && uploading.filter((u) => !u.error).length > 0;
  const numEnqueuedUploads = getNumEnqueuedUploads("jobUpload", job.datasetId);
  return (
    <div className={styles.fileList}>
      {!cannotRemoveFiles(job) && (
        <div className={styles.btns}>
          {hasFiles && (
            <>
              <h4 className={styles.heading}>Uploaded files</h4>
              <hr className={styles.line} />
            </>
          )}
          <Button
            className={styles.cancel}
            variant="text"
            onClick={() => {
              if (uploading) cancelAllUploadsOfKindInDs("jobUpload", job.datasetId);
              dispatch<typeof deleteJob>(deleteJob(job)).catch(() => {});
              if (currentDs) {
                dispatch<typeof deleteAllJobsInErrorState>(
                  deleteAllJobsInErrorState({
                    accountName: currentDs.owner.accountName,
                    datasetId: currentDs.id,
                    datasetName: currentDs.name,
                  }),
                ).catch(() => {});
              }
            }}
          >
            Cancel
          </Button>

          {/* all files that are to be uploaded have been uploaded */}
          {!hasOngoingUpload && hasUploadedFiles && (
            <LoadingButton
              className={styles.next}
              onClick={() => {
                dispatch<typeof startJob>(startJob(job)).catch(() => {});
              }}
              loading={starting}
            >
              Import from files
            </LoadingButton>
          )}

          {/* ongoing file uploads */}
          {hasOngoingUpload && (
            <Button
              className={styles.next}
              disabled={startingWhenUploaded}
              startIcon={<FontAwesomeIcon className="mr-1" icon="circle-notch" spin />}
              onClick={
                !startingWhenUploaded
                  ? () => {
                      dispatch<typeof updateStartWhenUploaded>(updateStartWhenUploaded(job, true));
                    }
                  : undefined
              }
            >
              {startingWhenUploaded ? "Proceeding when uploaded..." : "Next"}
            </Button>
          )}
        </div>
      )}
      {cannotRemoveFiles(job) && (
        <div className={styles.btns}>
          {hasUploadedFiles && (
            <>
              <h4 className={styles.heading}>Uploaded files</h4>
              <hr className={styles.line} />
            </>
          )}
          {job.status !== "finished" && (
            <Button variant="text" className={styles.cancel} onClick={() => dispatch<typeof resetJob>(resetJob(job))}>
              Back
            </Button>
          )}
        </div>
      )}

      {!!numEnqueuedUploads && (
        <div className={styles.numEnqueuedUploads}>
          {`There ${numEnqueuedUploads === 1 ? "is" : "are"} ${formatNumber(numEnqueuedUploads)} enqueued upload${
            numEnqueuedUploads === 1 ? "" : "s"
          }.`}{" "}
        </div>
      )}

      {/* Show the file list or text directing the user to add more files. */}
      <div className={styles.padFiles}>
        {!hasFiles && !numEnqueuedUploads ? (
          <p style={{ padding: "4px 2px 12px 2px" }}>
            Choose the files you want to upload. You can select more than one file at a time. You can also drag and drop
            files in the box above to start uploading.
          </p>
        ) : (
          isUploading &&
          uploading &&
          uploading.map((b) => (
            <FileRow {...getFileRowProps(job, { fileName: b.file.name, fileSize: b.file.size }, removeFile, b)} />
          ))
        )}
        {hasUploadedFiles &&
          job.files
            .slice(0)
            .reverse()
            .map((b) => <FileRow {...getFileRowProps(job, b, removeFile)} />)}
      </div>
    </div>
  );
};
export default JobUpload;
