import * as React from "react";
import { useHistory } from "react-router";
import { v4 as uuid } from "uuid";
import { factories, getWriter } from "@triplydb/data-factory";
import fetch from "#helpers/fetch.ts";
import { Button, Dialog, FontAwesomeIcon } from "../../components";
import useConstructConsoleUrl from "../../helpers/hooks/useConstructConsoleUrl";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { stringifyQuery } from "../../helpers/utils";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import Resource from "./Forms/Resource";
import type { ResourceData } from "./Forms/Types";

const factory = factories.compliant;
const CreateResource: React.FC<{}> = () => {
  const [open, setOpen] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const jobsUrl = useConstructUrlToApi()({
    pathname: `/datasets/${datasetPath}/jobs`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <>
      <Button
        color="secondary"
        elevation
        className="ml-3"
        onClick={() => setOpen(true)}
        title="Create a new resource in the dataset"
        startIcon={<FontAwesomeIcon icon="plus" />}
      >
        Create a new resource
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        title="Create a new resource"
        closeButton
      >
        <div className="px-5 pb-5">
          <Resource
            onSubmit={async (values: ResourceData) => {
              const id = factory.namedNode(`${consoleUrl}/${datasetPath}/id/r-${uuid()}`);
              const writer = getWriter({ format: "trig" });

              writer.addQuad(
                id,
                factory.namedNode("http://www.w3.org/1999/02/22-rdf-syntax-ns#type"),
                factory.namedNode(values.type!.id),
              );
              writer.addQuad(
                id,
                factory.namedNode("http://www.w3.org/2000/01/rdf-schema#label"),
                factory.literal(values.label),
              );
              for (const p of values.properties) {
                writer.addQuad(
                  id,
                  factory.namedNode(p.property!.id),
                  p.value?.datatype
                    ? factory.literal(p.value.id, factory.namedNode(p.value.datatype))
                    : factory.namedNode(p.value?.id!),
                );
              }

              const serialized: string = await new Promise((resolve, reject) => {
                writer.end((error: Error | undefined, result: string) => {
                  if (error) reject(error);
                  resolve(result);
                });
              });

              const body = new FormData();
              body.set("file", new Blob([serialized]), "data.trig");
              body.set("mergeGraphs", "true");
              await fetch(jobsUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );

              setOpen(false);

              history.push({
                search: stringifyQuery({ resource: id.value }),
              });
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default CreateResource;
