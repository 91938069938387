import getClassName from "classnames";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import formatNumber from "@core/utils/formatting";
import { Button, FlexContainer, Markdown } from "#components/index.ts";
import SiteSearch from "#components/SiteSearch/index.tsx";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

const BrowseButtons: React.FC<{}> = () => {
  const history = useHistory();
  const counts = useSelector((state: GlobalState) => state.config.clientConfig?.counts);
  if (!counts) return null;
  return (
    <div className={styles.browseButtons}>
      <Button
        title="Browse datasets"
        size="large"
        color="inherit"
        variant="outlined"
        className={styles.browseButton}
        onClick={() => history.push("/browse/datasets")}
      >
        {formatNumber(counts.datasets)} datasets
      </Button>
      <Button
        title="Browse stories"
        size="large"
        color="inherit"
        variant="outlined"
        className={styles.browseButton}
        onClick={() => history.push("/browse/stories")}
      >
        {formatNumber(counts.stories)} stories
      </Button>
      <Button
        title="Browse queries"
        size="large"
        color="inherit"
        variant="outlined"
        className={styles.browseButton}
        onClick={() => history.push("/browse/queries")}
      >
        {formatNumber(counts.queries)} queries
      </Button>
    </div>
  );
};

const Banner: React.FC<{}> = () => {
  const { welcomeText, bannerImage, triplyDbComMode, triplydbDemoLink } = useSelector((state: GlobalState) => {
    return {
      welcomeText: state.config.clientConfig?.branding.welcomeMessage,
      bannerImage: state.config.clientConfig?.branding.banner,
      triplyDbComMode: !!state.config.clientConfig?.triplydb,
      triplydbDemoLink: state.config.clientConfig?.triplydb?.triplydbDemoLink,
    };
  });
  const numberOfPublishedStatements = useSelector(
    (state: GlobalState) => state.config.clientConfig?.triplydb?.numberOfStatements,
  );
  const authenticatedUser = useAuthenticatedUser();
  return (
    <header
      className={getClassName(styles.banner, {
        [styles.customBanner]: !!bannerImage && !triplyDbComMode,
        [styles.tbdBanner]: !!triplyDbComMode,
      })}
    >
      {!triplyDbComMode && bannerImage ? (
        <picture className={styles.backgroundImage}>
          <source srcSet={`${bannerImage}&w=2000`} type="image/webp" />
          <source srcSet={`${bannerImage.replace("webp", "png")}&w=2000`} type="image/png" />
          <img alt="" src={`${bannerImage.replace("webp", "png")}&w=2000`} />
        </picture>
      ) : (
        <div className={styles.backgroundImage}></div>
      )}
      {triplyDbComMode && (
        <div className={styles.tbdWrapper}>
          <div className="grow"></div>
          <div className={getClassName("flex", "pt-5", styles.tbdContent)}>
            <div className={getClassName(styles.tbdTextContent, "px-5", "flex")}>
              <div className="grow"></div>
              <div className={styles.tbdTitle}>
                <h1>
                  <em>Store</em>, <em>explore</em> and <em>share</em> linked&nbsp;data
                </h1>
                <div className="my-3">
                  <b>{formatNumber(numberOfPublishedStatements || 0)}</b> published statements
                </div>
              </div>
              <div className={"mt-4 mb-3 flex g-3"}>
                {triplydbDemoLink && (
                  <a href={triplydbDemoLink} target="_blank">
                    <Button className={authenticatedUser ? styles.secondaryButton : styles.primaryButton} elevation>
                      Book a demo
                    </Button>
                  </a>
                )}
                {!authenticatedUser && (
                  <Link to={"/register?returnTo=%2F"}>
                    <Button className={styles.secondaryButton} elevation>
                      Create a free account
                    </Button>
                  </Link>
                )}
              </div>
              <div className="grow"></div>
            </div>
            <div className={getClassName(styles.tbdImage, "mx-5")}>
              {bannerImage && (
                <picture>
                  <source srcSet={`${bannerImage}&w=800`} type="image/webp" />
                  <source srcSet={`${bannerImage.replace("webp", "png")}&w=800`} type="image/png" />
                  <img className={styles.sideImage} alt="" src={`${bannerImage.replace("webp", "png")}&w=800`}></img>
                </picture>
              )}
            </div>
          </div>
          <div className="grow"></div>
        </div>
      )}
      <FlexContainer className={styles.wrapper}>
        {!triplyDbComMode && welcomeText && <Markdown className={styles.welcomeMessage}>{welcomeText}</Markdown>}
        <div className={styles.outerSearchContainer}>
          <div className={getClassName("my-7 px-1", styles.innerSearchContainer)}>
            <SiteSearch className={styles.searchBar} mayShrink={false} />
          </div>
        </div>
        <BrowseButtons />
      </FlexContainer>
    </header>
  );
};

export default Banner;
