import getClassName from "classnames";
import * as connectedReactRouter from "connected-react-router";
import * as React from "react";
import { useSelector } from "react-redux";
import type { Models } from "@triply/utils";
import { Alert, Button, Dialog, FileUploadButton } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { registerBulk } from "#reducers/admin.ts";
import type { GlobalState } from "#reducers/index.ts";
import * as styles from "./style.scss";

export interface Props {
  open: boolean;
  onChange: () => void;
}
const templateCsv = `
accountName,email,role,expiresAt
john,john@triply.cc,regular,12-31-2025
`.trim();
const BulkUpload: React.FC<Props> = ({ open, onChange }) => {
  const dispatch = useDispatch();
  const goBack = () => dispatch(connectedReactRouter.goBack());
  const [uploadingUserBulk, setUploadingUserBulk] = React.useState(false);
  const [userBulkResult, setUserBulkResult] = React.useState<Models.BulkUserReport>();
  const [userBulkError, setUserBulkError] = React.useState("");
  const handleBulkUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    setUploadingUserBulk(true);
    setUserBulkResult(undefined);
    setUserBulkError("");
    return dispatch<typeof registerBulk>(registerBulk(file))
      .then(
        (response) => {
          setUserBulkResult(response.body);
          onChange();
        },
        (e: Error) => {
          setUserBulkError(e.message);
        },
      )
      .catch(() => {})
      .finally(() => setUploadingUserBulk(false));
  };
  const triplyDbComMode = useSelector((state: GlobalState) => {
    return !!state.config?.clientConfig?.triplydb;
  });

  return (
    <Dialog open={open} onClose={goBack} title="Add users in bulk" maxWidth="lg" fullWidth>
      <div className="ml-5 mr-5 mb-5">
        <div className="mb-5">
          To create multiple user accounts at once, upload a CSV file based on{" "}
          <a
            href={`data:application/octet-stream,${encodeURIComponent(templateCsv)}`}
            download="bulk-user-template.csv"
          >
            this template
          </a>
          . Only the fields <em>accountName</em> and <em>email</em> are required. Possible values for the <em>role</em>{" "}
          field are: {triplyDbComMode && "'light', "}'regular' and 'siteAdmin'.
        </div>
        <div className="mb-5">
          <FileUploadButton accept=".csv" onChange={handleBulkUpload} loading={uploadingUserBulk}>
            Upload user list (CSV)
          </FileUploadButton>
        </div>
        {userBulkResult && (
          <div className={getClassName("mb-5", styles.bulkResult)}>
            {userBulkResult.success > 0 && <div>{`Successfully created ${userBulkResult.success} users.`}</div>}
            {userBulkResult.errors.length > 0 && (
              <div>
                {`${userBulkResult.errors.length} user accounts could not be created:`}
                <ul>
                  {userBulkResult.errors.map((e, i) => (
                    <li key={i}>{`${e.account}: ${e.message}`}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
        {userBulkError && (
          <div className="mb-5">
            <Alert transparent message={userBulkError} />
          </div>
        )}
        <div>
          <Button variant="text" onClick={goBack} disabled={uploadingUserBulk}>
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default BulkUpload;
