import { Alert, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import * as React from "react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import type { AutoGeneratedIdType } from "@triply/utils/Models";
import { FormField, Label, LoadingButton } from "#components/index.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import type { Account } from "#reducers/accountCollection.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";

export type AutogeneratedIdFormInput = {
  autogeneratedIdType: AutoGeneratedIdType;
};
const AutoGeneratedIdForm: React.FC<{
  currentDs: Dataset;
  currentAccount: Account;
  initialValues: {
    autogeneratedIdType?: AutoGeneratedIdType;
  };
  onSubmit: SubmitHandler<AutogeneratedIdFormInput>;
}> = ({ currentDs, initialValues, onSubmit }) => {
  const consoleUrl = useConstructConsoleUrl()();
  const prefixes = useDatasetPrefixes();
  const datasetUrl = `${consoleUrl}/${currentDs.owner.accountName}/${currentDs.name}`;
  const baseIri = prefixes.find((prefix) => prefix.prefixLabel === "id")?.iri || `${datasetUrl}/id/`;
  const { control, handleSubmit, formState, watch } = useForm<AutogeneratedIdFormInput>({
    defaultValues: {
      autogeneratedIdType: initialValues.autogeneratedIdType || "uuid",
    },
    mode: "onChange",
  });

  const autogeneratedIdTypeWatch = watch("autogeneratedIdType");

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormField label="Type" className="mb-6">
          <Controller
            name="autogeneratedIdType"
            control={control}
            rules={{ required: "A type is required" }}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <RadioGroup
                value={autogeneratedIdTypeWatch}
                aria-label="Autogenerated ID Type"
                onChange={(e) => onChange(e.target.value)}
                row
              >
                <FormControlLabel value={"uuid" as AutoGeneratedIdType} control={<Radio />} label="UUID" />
                <FormControlLabel value={"hex8" as AutoGeneratedIdType} control={<Radio />} label="Hexadecimal 8" />
                <FormControlLabel value={"hex10" as AutoGeneratedIdType} control={<Radio />} label="Hexadecimal 10" />
              </RadioGroup>
            )}
          />
        </FormField>
        {autogeneratedIdTypeWatch && (
          <Alert
            severity="info"
            iconMapping={{
              info: <Typography variant="body1">Eg: </Typography>,
            }}
            className="my-3"
          >
            {autogeneratedIdTypeWatch === "uuid" ? `${baseIri}9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d` : ""}
            {autogeneratedIdTypeWatch === "hex8" ? `${baseIri}2407E400` : ""}
            {autogeneratedIdTypeWatch === "hex10" ? `${baseIri}2407E00F48` : ""}
          </Alert>
        )}

        <LoadingButton
          type="submit"
          color="secondary"
          disabled={initialValues.autogeneratedIdType === autogeneratedIdTypeWatch}
          loading={formState.isSubmitting}
          aria-label="Save"
        >
          Save
        </LoadingButton>
      </form>
      {!formState.isSubmitting && formState.isSubmitted && formState.isSubmitSuccessful && (
        <div className="mt-4">
          <Label success message="You have successfully updated the auto generated id settings" />
        </div>
      )}
    </div>
  );
};

export default AutoGeneratedIdForm;
