import { closeBrackets } from "@codemirror/autocomplete";
import { history } from "@codemirror/commands";
import { bracketMatching, foldGutter } from "@codemirror/language";
import { lintGutter } from "@codemirror/lint";
import { highlightSelectionMatches, RegExpCursor } from "@codemirror/search";
import type { Range } from "@codemirror/state";
import { EditorState, StateEffect, StateField } from "@codemirror/state";
import {
  crosshairCursor,
  Decoration,
  drawSelection,
  dropCursor,
  EditorView,
  highlightActiveLineGutter,
  lineNumbers,
  rectangularSelection,
} from "@codemirror/view";
import { githubLight } from "@uiw/codemirror-theme-github";
import getClassName from "classnames";
import { escapeRegExp } from "lodash-es";
import * as React from "react";
import { SparqlLanguage } from "./grammar/sparqlLang.ts";
import * as styles from "./style.scss";

export interface Props {
  initialValue: string;
  customRegExToHighlight?: string;
}

const ReadOnlySparqlEditor: React.FC<Props> = ({ initialValue: _initialValue, customRegExToHighlight }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const viewRef = React.useRef<EditorView | null>(null);
  const initialValue = React.useRef(_initialValue);

  const highlightEffect = StateEffect.define<Range<Decoration>[]>();

  const highlightExtension = StateField.define({
    create() {
      return Decoration.none;
    },
    update(value, transaction) {
      value = value.map(transaction.changes);

      for (let effect of transaction.effects) {
        if (effect.is(highlightEffect)) value = value.update({ add: effect.value, sort: true });
      }

      return value;
    },
    provide: (f) => EditorView.decorations.from(f),
  });

  // Mount & Cleanup hook
  React.useEffect(() => {
    if (containerRef.current) {
      const initialState = EditorState.create({
        doc: initialValue.current,
        extensions: [
          SparqlLanguage,
          lineNumbers(),
          highlightActiveLineGutter(),
          history(),
          foldGutter(),
          drawSelection(),
          dropCursor(),
          EditorState.allowMultipleSelections.of(true),
          EditorState.readOnly.of(true),
          githubLight,
          bracketMatching(),
          closeBrackets(),
          rectangularSelection(),
          crosshairCursor(),
          lintGutter(),
          highlightSelectionMatches(),
          highlightExtension,
        ],
      });
      viewRef.current = new EditorView({
        state: initialState,
        parent: containerRef.current,
      });

      if (customRegExToHighlight) {
        let cursor = new RegExpCursor(viewRef.current.state.doc, escapeRegExp(customRegExToHighlight), {
          ignoreCase: true,
        });
        const highlightDecoration = Decoration.mark({
          attributes: { style: "background-color: yellow" },
        });
        do {
          cursor.next();
          if (cursor.value.from && cursor.value.to) {
            viewRef.current.dispatch({
              effects: highlightEffect.of([highlightDecoration.range(cursor.value.from, cursor.value.to)]),
            });
          }
        } while (!cursor.done);
      }

      return () => {
        viewRef.current?.destroy();
        viewRef.current = null;
      };
    }
  }, [customRegExToHighlight, highlightEffect, highlightExtension]);

  return <div ref={containerRef} className={getClassName(styles.editor)} />;
};

export default ReadOnlySparqlEditor;
