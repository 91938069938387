import getClassName from "classnames";
import { push } from "connected-react-router";
import * as React from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "@core/utils/formatting";
import { Avatar, Button, Markdown, ResourceWidget } from "#components/index.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import type { Dataset } from "#reducers/datasetManagement.ts";
import { copyDataset } from "#reducers/datasetManagement.ts";
import type { ResourceDescriptions } from "#reducers/resourceDescriptions.ts";
import {
  getStatementsAsTree,
  getWidgetCollection,
  selectAudio,
  selectGeometry,
  selectImage,
  selectLabel,
  selectVideo,
} from "#reducers/resourceDescriptions.ts";
import * as styles from "./style.scss";

namespace StarterDatasetWidget {
  export interface Props {
    dataset: Dataset;
    accountName: string;
    resourceDescriptions?: ResourceDescriptions;
    className?: string;
  }
}

const StarterDatasetWidget: React.FC<StarterDatasetWidget.Props> = React.memo(
  ({ dataset: ds, accountName, className, resourceDescriptions }) => {
    const link = "/" + ds.owner.accountName + "/" + ds.name;
    const ref = React.useRef<HTMLDivElement>(null);
    const [showOverlay, setShowOverlay] = React.useState(false);
    React.useEffect(() => {
      setShowOverlay(!!ref.current?.scrollHeight && ref.current?.scrollHeight > 150);
    }, [ref.current?.scrollHeight]);
    const dispatch = useDispatch();
    return (
      <div className={getClassName(className)}>
        <div className={getClassName("flex center", styles.header)}>
          <div className="flex grow">
            <Link to={link} className="noLinkDecoration">
              <Avatar
                size="lg"
                avatarName={ds.displayName || ds.name}
                avatarUrl={ds.avatarUrl}
                title={ds.displayName || ds.name}
                alt=""
              />
            </Link>

            <div className={styles.primaryInfo}>
              <div className={getClassName("flex", styles.title)}>
                <h2 className="headerSpacing">
                  <Link to={link}>{ds.displayName || ds.name}</Link>
                </h2>
              </div>

              <div className={getClassName("mt-3", styles.statements)}>{`${formatNumber(
                ds.statements,
              )} statements`}</div>
            </div>
          </div>
          <Button
            className={styles.importDemo}
            color="secondary"
            onClick={() => {
              dispatch<typeof copyDataset>(copyDataset(ds, accountName)).then(
                ({ body }) => {
                  const copiedDatasetName = body.name;
                  dispatch(push(`/${accountName}/${copiedDatasetName}`));
                },
                (e: Error) => {
                  console.error(e.message);
                },
              );
            }}
          >
            Import starter dataset
          </Button>
        </div>

        <div ref={ref} className={styles.description}>
          <Markdown className={styles.markdown} compact>
            {ds.description}
          </Markdown>
          {showOverlay && <div className={styles.overlay} />}
        </div>
        {showOverlay && (
          <Link className={getClassName("py-3")} to={link}>
            Read further
          </Link>
        )}

        {resourceDescriptions && ds.exampleResources && ds.exampleResources.length > 0 && (
          <div className={styles.exampleResources}>
            {ds.exampleResources.map((r) => {
              const desc = resourceDescriptions[r];
              const tree = desc && getStatementsAsTree(r, desc.statements, "forward");
              return (
                tree && (
                  <ResourceWidget
                    key={r}
                    resource={tree.getTerm()}
                    widgetCollection={getWidgetCollection(tree, [
                      selectLabel,
                      selectImage,
                      selectGeometry,
                      selectAudio,
                      selectVideo,
                    ])}
                    linkPath={`/${ds.owner.accountName}/${ds.name}/browser`}
                  />
                )
              );
            })}
          </div>
        )}
      </div>
    );
  },
);
export default StarterDatasetWidget;
