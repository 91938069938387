import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import {
  applyRuleConfig,
  getDisplayNameValidations,
  getStoryNameValidations,
  required,
  toStringValidator,
} from "@core/utils/validation";
import type { AccessLevel } from "@triply/utils/Models";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import { AccessLevels, Alert, Button, FormField, NameAndUrl } from "#components/index.ts";
import type { Account } from "#reducers/accountCollection.ts";

const storyNameValidator = toStringValidator(getStoryNameValidations());
const displayNameValidator = toStringValidator([
  ...getDisplayNameValidations({ messageSubject: "A story title" }),
  applyRuleConfig(required, {
    formatMessage: () => `A story title is required.`,
  }),
]);

namespace StoryForm {
  export interface FormData {
    name: string;
    displayName: string;
    accessLevel: AccessLevel;
  }
  export interface Props extends Partial<ReduxForm.InjectedFormProps<FormData>> {
    className?: string;
    style?: React.CSSProperties;
    currentAccount: Account;
    cancelFunction?: React.EventHandler<React.MouseEvent<any>>;
    updating?: boolean;
  }
  export interface State {}
}

const StoryForm = ReduxForm.reduxForm<StoryForm.FormData, StoryForm.Props>({
  form: "story",
  validate: memoizee(
    (formData: StoryForm.FormData, _props: StoryForm.Props) => {
      return {
        name: storyNameValidator(formData.name),
        displayName: displayNameValidator(formData.displayName),
      };
    },
    { max: 10 },
  ),
  warn: memoizee(
    (formData: StoryForm.FormData, props: StoryForm.Props) => {
      return {
        name:
          (props.updating &&
            formData.name &&
            props.initialValues?.name &&
            formData.name.toLowerCase() !== props.initialValues.name.toLowerCase() &&
            "Changing the story URL will break existing links to this story") ||
          undefined,
      };
    },
    { max: 10 },
  ),
})(
  class StoryForm extends React.PureComponent<
    StoryForm.Props & ReduxForm.InjectedFormProps<FormData>,
    StoryForm.State
  > {
    cancel = (e: React.MouseEvent<any>) => {
      e.preventDefault();
      this.props.cancelFunction?.(e);
    };

    render() {
      const {
        currentAccount,
        handleSubmit,
        updating,
        error,
        submitting,
        className,
        style,
        invalid,
        cancelFunction,
        change,
        initialValues,
        pristine,
      } = this.props;

      return (
        <form onSubmit={handleSubmit} className={getClassName("px-5 pb-5", className)} style={style}>
          <NameAndUrl
            changeFormValues={change}
            initialSlug={initialValues.name}
            formIsPristine={pristine}
            autoFocus
            nameFieldName="displayName"
            nameFieldLabel="Title"
            slugFieldName="name"
            slugFieldLabel="URL"
            urlPrefixPath={`/${currentAccount.accountName}/-/stories/`}
            className="mb-6"
            breakPoint={580}
          />

          <FormField label="Access level" className="mb-6" breakPoint={580}>
            <AccessLevels
              name="accessLevel"
              type="story"
              accountType={currentAccount.type}
              changeAccessLevel={(value) => this.props.change?.("accessLevel", value)}
            />
          </FormField>

          <Alert transparent className="mt-5" message={error} />

          <div className="form-group mt-6">
            <LoadingButton
              type="submit"
              color="secondary"
              disabled={invalid}
              onClick={handleSubmit}
              loading={submitting}
            >
              {updating ? "Update" : "Create"} story
            </LoadingButton>

            {cancelFunction && (
              <Button disabled={submitting} onClick={cancelFunction} variant="text" className="mx-3">
                Cancel
              </Button>
            )}
          </div>
        </form>
      );
    }
  } as any,
);

export default StoryForm;
