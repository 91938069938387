import getClassName from "classnames";
import memoizee from "memoizee";
import * as React from "react";
import * as ReduxForm from "redux-form";
import {
  applyRuleConfig,
  datasetDescriptionValidations,
  getDatasetNameValidations,
  getDisplayNameValidations,
  required,
  toStringValidator,
} from "@core/utils/validation";
import LoadingButton from "#components/Button/LoadingButton.tsx";
import type { MarkdownEditField } from "#components/index.ts";
import { AccessLevels, Alert, Button, FormField, MarkdownEditFieldRedux, NameAndUrl } from "#components/index.ts";
import type { Account } from "#reducers/accountCollection.ts";

const descriptionValidator = toStringValidator(datasetDescriptionValidations);
const datasetNameValidator = toStringValidator(getDatasetNameValidations());
const datasetDisplayNameValidator = toStringValidator([
  ...getDisplayNameValidations({ messageSubject: "A dataset name" }),
  applyRuleConfig(required, {
    formatMessage: () => `A dataset name is required.`,
  }),
]);

namespace DatasetAddForm {
  export interface FormData {
    name: string;
    description?: string;
    accessLevel: "public" | "private" | "internal";
    displayName?: string;
  }
  export type Props = OwnProps & Partial<ReduxForm.InjectedFormProps<FormData>>;

  export type ReduxFormProps = ReduxForm.ConfigProps<FormData>;
  export interface OwnProps extends Partial<ReduxFormProps> {
    formClassName?: string;
    className?: string;
    cancel?: React.EventHandler<React.MouseEvent<any>>;
    disableAutoFocus?: boolean;
    currentAccount: Account;
  }
  export interface State {}
}

const DatasetAddForm = ReduxForm.reduxForm<DatasetAddForm.FormData, DatasetAddForm.Props>({
  form: "datasetAdd",
  validate: memoizee(
    (formData: DatasetAddForm.FormData, _props: DatasetAddForm.Props) => {
      return {
        name: datasetNameValidator(formData.name),
        displayName: datasetDisplayNameValidator(formData.displayName),
        description: descriptionValidator(formData.description),
      };
    },
    { max: 10 },
  ),
  enableReinitialize: true,
})(
  class DatasetAddForm extends React.PureComponent<
    DatasetAddForm.Props & ReduxForm.InjectedFormProps<FormData>,
    DatasetAddForm.State
  > {
    cancel = (e: React.MouseEvent<any>) => {
      e.preventDefault();
      this.props.cancel?.(e);
    };
    render() {
      const {
        handleSubmit,
        error,
        submitting,
        formClassName,
        className,
        invalid,
        cancel,
        disableAutoFocus,
        change,
        initialValues,
        pristine,
        currentAccount,
      } = this.props;

      return (
        <div className={className}>
          <form onSubmit={handleSubmit} className={getClassName(formClassName)}>
            <NameAndUrl
              changeFormValues={change}
              initialSlug={initialValues.name}
              formIsPristine={pristine}
              autoFocus={!disableAutoFocus}
              nameFieldName="displayName"
              nameFieldLabel="Dataset name"
              slugFieldName="name"
              slugFieldLabel="URL"
              className="mb-6"
              urlPrefixPath={`/${currentAccount.accountName}/`}
            />

            <FormField label="Description (optional)" className="mb-6">
              <ReduxForm.Field<ReduxForm.BaseFieldProps<MarkdownEditField.Props>>
                name="description"
                props={{
                  type: "text",
                  fullWidth: true,
                  multiline: true,
                  rows: 5,
                }}
                component={MarkdownEditFieldRedux}
              />
            </FormField>

            <FormField label="Access level" className="mb-6">
              <AccessLevels
                name="accessLevel"
                type="dataset"
                accountType={currentAccount.type}
                changeAccessLevel={(value) => this.props.change?.("accessLevel", value)}
              />
            </FormField>

            <Alert className="mt-5" transparent message={error} />

            <div className="form-group mt-5">
              <LoadingButton
                color="secondary"
                disabled={invalid}
                type="submit"
                onClick={handleSubmit}
                loading={submitting}
              >
                Add dataset
              </LoadingButton>
              {!!cancel && (
                <Button onClick={this.cancel} className="ml-2" variant="text">
                  Cancel
                </Button>
              )}
            </div>
          </form>
        </div>
      );
    }
  } as any,
);

export default DatasetAddForm;
